import { createActionCreator as action, createType } from '../../utils/redux';
import namespace from './namespace';

const type = createType(namespace);

export const SET_USER_TOKEN = type('SET_USER_TOKEN');
export const setUserToken = action(SET_USER_TOKEN, 'token');

export const FETCH_USER = type('FETCH_USER');
export const fetchUser = action(FETCH_USER);

export const RECEIVE_USER = type('RECEIVE_USER');
export const receiveUser = action(RECEIVE_USER, 'user');

export const LOGIN = type('LOGIN');
export const login = action(LOGIN, 'email', 'password');

export const LOGIN_FAILED = type('LOGIN_FAILED');

export const SET_ACTIVE_ORGANIZATION = type('SET_ACTIVE_ORGANIZATION');
export const setActiveOrganization = action(
  SET_ACTIVE_ORGANIZATION,
  'organization',
  'forceReload',
  'preserveCurrentUrl'
);

// export const INITIALIZED = type('INITIALIZED');
//export const initialized = action(INITIALIZED);
export const LOGOUT = type('LOGOUT');
export const logout = action(LOGOUT);

export const CLEAR_USER = type('CLEAR_USER');
export const clearUser = action(CLEAR_USER);
