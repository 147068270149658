import { takeEvery, fork, call, put, select } from 'redux-saga/effects';
import {
  FETCH_PRODUCTS,
  // SHOW_PRODUCT_CREATE_MODAL,
  CLOSE_PRODUCT_CREATE_MODAL,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  FETCH_FAILED,
  RECEIVE_PRODUCTS,
  FETCH_PRODUCT_TYPES,
  RECEIVE_PRODUCT_TYPES,
  FETCH_PRODUCTS_OF_TYPE,
  receiveProductsOfType
} from './actions';
import { page } from './selectors';
import apiClient from '../../services/api-service';
import { normalize } from '../../utils/response';
import { navigate } from 'modules/navigation/actions';
import { actions as NotificationActions } from 'modules/notifications';

function* handleFetchProducts({ page }) {
  try {
    const {
      data: {
        data: products,
        meta: { pagination }
      }
    } = yield call(apiClient.get, '/V1/products', {
      params: {
        page
      }
    });
    yield put({
      type: RECEIVE_PRODUCTS,
      products: normalize(products),
      pagination
    });
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

function* handleWatchFetchProducts() {
  yield takeEvery(FETCH_PRODUCTS, handleFetchProducts);
}
function* handleCreateProduct({ product }) {
  try {
    yield call(apiClient.post, '/V1/products', product);
    yield put({ type: CREATE_PRODUCT_SUCCESS });
    yield put({ type: CLOSE_PRODUCT_CREATE_MODAL });
    yield put(
      NotificationActions.add({
        variant: 'success',
        content: 'Tuote luotu. Avaa/päivitä tuotelistaus klikkaamalla.',
        onClickAction: navigate('/hallitse/tuotteet')
      })
    );
  } catch {
    yield put({ type: CREATE_PRODUCT_ERROR });
  }
}

function* handleWatchCreateProduct() {
  yield takeEvery(CREATE_PRODUCT, handleCreateProduct);
}

function* handleEditProduct({ product }) {
  try {
    yield call(apiClient.patch, `/V1/products/${product.id}`, product);
    const p = yield select(page);
    yield put({ type: FETCH_PRODUCTS, page: p });
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

function* handleWatchEditProduct() {
  yield takeEvery(EDIT_PRODUCT, handleEditProduct);
}

function* handleDeleteProduct({ product: { id } }) {
  try {
    yield call(apiClient.delete, `/V1/products/${id}`);
    const p = yield select(page);
    yield put({ type: FETCH_PRODUCTS, page: p });
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

function* handleWatchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, handleDeleteProduct);
}

function* handleFetchProductTypes() {
  try {
    const {
      data: { data: types }
    } = yield call(apiClient.get, `/V1/product-types`);
    yield put({ type: RECEIVE_PRODUCT_TYPES, productTypes: normalize(types) });
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

function* handleWatchFetchProductTypes() {
  yield takeEvery(FETCH_PRODUCT_TYPES, handleFetchProductTypes);
}

function* handleFetchProductsOfTypes({ productType }) {
  try {
    const {
      data: { data: products }
    } = yield call(apiClient.get, `/V1/products`, {
      params: {
        'product_type.id': productType.id
      }
    });
    yield put(receiveProductsOfType(productType.name, normalize(products)));
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

function* handleWatchFetchProductsOfTypes() {
  yield takeEvery(FETCH_PRODUCTS_OF_TYPE, handleFetchProductsOfTypes);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchFetchProductTypes);
  yield fork(handleWatchFetchProducts);
  yield fork(handleWatchCreateProduct);
  yield fork(handleWatchEditProduct);
  yield fork(handleWatchDeleteProduct);
  yield fork(handleWatchFetchProductsOfTypes);
}
