import { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  render() {
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
