import { takeEvery, fork, call, put } from 'redux-saga/effects';
import { FETCH_ROLES, receiveRoles, fetchRolesFailed } from './actions';

import api from '../../services/api-service';
import { normalize } from '../../utils/response';

function* handleFetchRoles(action) {
  try {
    const {
      data: { data }
    } = yield call(api.get, '/V1/roles');
    yield put(receiveRoles(normalize(data)));
  } catch (e) {
    yield put(fetchRolesFailed(e));
  }
}

function* handleWatchFetchRoles() {
  yield takeEvery(FETCH_ROLES, handleFetchRoles);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchFetchRoles);
}
