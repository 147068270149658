import { useSelector, useDispatch } from 'react-redux';
import { getNotifications } from 'modules/notifications/selectors';
import { actions, effects } from 'modules/notifications';

const { add, remove } = actions;
const { flash } = effects;

export const useNotifications = () => {
  const notifications = useSelector(getNotifications);
  const dispatch = useDispatch();

  return {
    add: n => dispatch(add(n)),
    remove: n => dispatch(remove(n)),
    flash: (n, ms) => dispatch(flash(n, ms)),
    notifications
  };
};
