import { pipe, path, prop } from 'ramda';
import { createSelector } from 'reselect';
import namespace from './namespace';

const root = path(namespace);

export const clients = pipe(
  root,
  prop('clients')
);

export const clientTypes = pipe(
  root,
  prop('clientTypes')
);

export const pagination = pipe(
  root,
  prop('pagination')
);

export const page = pipe(
  pagination,
  prop('currentPage')
);

export const pageRootless = pipe(
  prop('pagination'),
  prop('currentPage')
);

export const isFetching = pipe(
  root,
  prop('fetching')
);

export const isLoaded = pipe(
  root,
  prop('loaded')
);

export const hasError = pipe(
  root,
  prop('error')
);

export const showModal = pipe(
  root,
  prop('ui'),
  prop('clientCreateModal'),
  prop('show')
);

export const modalFetching = pipe(
  root,
  prop('ui'),
  prop('clientCreateModal'),
  prop('fetching')
);

export const makeLoadingSelector = () => {
  return createSelector(
    [modalFetching, clientTypes],
    (isFetching, clientTypes) => {
      return isFetching || clientTypes.length === 0;
    }
  );
};
