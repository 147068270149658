import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { roles } from 'services/permission';
import FallbackRedirect from 'components/fallback-redirect';

/**
 * Estate routes
 */

// estate overview
const EstateMainPage = lazy(() => import('containers/estate-container'));
const EstateOverviewPage = lazy(() =>
  import('containers/estate-overview-container')
);
const EstateOverview = props => (
  <EstateMainPage {...props}>
    <EstateOverviewPage />
  </EstateMainPage>
);
// Estate details
const EstateDetailsPage = lazy(() =>
  import('containers/estate-details-container')
);
const EstateDetails = props => (
  <EstateMainPage {...props}>
    <EstateDetailsPage />
  </EstateMainPage>
);

const EstateContractsPage = lazy(() =>
  import('containers/estate-contracts-container')
);
const EstateContracts = props => (
  <EstateMainPage {...props}>
    <EstateContractsPage />
  </EstateMainPage>
);

const EstateTasksPage = lazy(() => import('pages/estate-tasks'));
const EstateTasks = props => (
  <EstateMainPage {...props}>
    <EstateTasksPage />
  </EstateMainPage>
);

// Estate equipment survey transcript
const EstateSurveyTranscriptPage = lazy(() =>
  import('containers/equipment-survey-transcript-container')
);
const EstateSurveyTranscript = props => (
  <EstateMainPage {...props}>
    <EstateSurveyTranscriptPage />
  </EstateMainPage>
);
// Estate equipment survey transcript edit page
const EstateSurveyTranscriptEdit = lazy(() =>
  import('containers/equipment-survey-transcript-edit-container')
);

// Estate contracting blueprint page
const EstateContractingBlueprintPage = lazy(() =>
  import('containers/contracting-blueprint-container')
);
const EstateContractingBlueprint = props => (
  <EstateMainPage {...props}>
    <EstateContractingBlueprintPage />
  </EstateMainPage>
);

// Estate attachments
const EstateAttachmentsPage = lazy(() =>
  import('containers/estate-attachments-container')
);
const EstateAttachments = props => (
  <EstateMainPage {...props}>
    <EstateAttachmentsPage />
  </EstateMainPage>
);

const EstateLayoutDesigner = lazy(() =>
  import('containers/layout-designer-container')
);

// Estate contracts new contract
const NewContractContainer = lazy(() =>
  import('containers/new-contract-container')
);

// Manage subroutes
const ManageMainPage = lazy(() => import('pages/manage'));
const Product = lazy(() => import('pages/product'));
const Reports = lazy(() => import('pages/reports'));
const Client = lazy(() => import('pages/client'));
const NotFound = lazy(() => import('pages/not-found'));
const Offer = lazy(() => import('pages/offer'));

const ManageEstatesPage = lazy(() => import('pages/estates'));
const ManageEstates = props => (
  <ManageMainPage {...props}>
    <ManageEstatesPage />
  </ManageMainPage>
);

const ManageUsersPage = lazy(() => import('pages/users'));
const ManageUsers = props => (
  <ManageMainPage {...props}>
    <ManageUsersPage />
  </ManageMainPage>
);

const ManageClientsPage = lazy(() => import('containers/clients-container'));
const ManageClients = props => (
  <ManageMainPage {...props}>
    <ManageClientsPage />
  </ManageMainPage>
);

const ManageProductsPage = lazy(() => import('pages/products'));

const ManageProducts = props => (
  <ManageMainPage {...props}>
    <ManageProductsPage />
  </ManageMainPage>
);

const ManageEnergyCompaniesPage = lazy(() => import('pages/energy-companies'));
const ManageEnergyCompanies = props => (
  <ManageMainPage {...props}>
    <ManageEnergyCompaniesPage />
  </ManageMainPage>
);
const EnergyCompany = lazy(() => import('pages/energy-company'));

// Tasks
const TasksPage = lazy(() => import('pages/tasks'));
const TaskPage = lazy(() => import('pages/task'));

// Contracts
const ContractsPage = lazy(() => import('pages/contracts'));
const ContractPage = lazy(() => import('pages/contract'));

const LeadsPage = lazy(() => import('pages/leads'));

// Users
const ProfilePage = lazy(() => import('pages/profile'));

// Organization
const OrganizationPage = lazy(() => import('pages/organization-settings'));

// Search
const SearchPage = lazy(() => import('pages/search'));

// Billing
const BillingPage = lazy(() => import('pages/billing'));
const InvoicePage = lazy(() => import('pages/invoice'));

// User must be authenticated in order to use these routes
// Export protectedRoutes so that they can be used elsewhere (e.g sidebar navigation)
export default [
  {
    path: '/',
    exact: true,
    // All roles might not have access to the 'myynti' route
    component: () => <FallbackRedirect to="/myynti" fallback="/profiili" />
  },
  {
    path: '/profiili',
    exact: true,
    component: ProfilePage
  },
  {
    path: '/organisaatio',
    exact: true,
    component: OrganizationPage,
    requiredRoles: [roles.admin, roles.editor]
  },
  {
    path: '/haku',
    exact: true,
    component: SearchPage,
    requiredRoles: [roles.admin, roles.editor, roles.salesperson]
  },
  {
    name: 'estate',
    path: '/kohteet/:id',
    exact: true,
    component: props => {
      const {
        /* eslint-disable-next-line */
        match: {
          params: { id }
        }
      } = props;
      return <Redirect to={`/kohteet/${id}/yleistiedot`} />;
    },
    routes: [
      {
        name: 'overview',
        sidebar: {
          title: 'Tilanne'
        },
        path: '/kohteet/:id/tilanne',
        exact: true,
        component: EstateOverview
      },
      {
        name: 'details',
        sidebar: {
          title: 'Yleistiedot'
        },
        path: '/kohteet/:id/yleistiedot',
        exact: true,
        component: EstateDetails
      },
      {
        name: 'surveytranscript',
        sidebar: {
          title: 'Laitekatselmuspöytäkirja'
        },
        path: '/kohteet/:id/laitekatselmuspoytakirja',
        exact: true,
        component: EstateSurveyTranscript,
        blacklist: ['SILP']
      },
      {
        name: 'surveytranscriptedit',
        path: '/kohteet/:id/laitekatselmuspoytakirja/muokkaa',
        exact: true,
        component: EstateSurveyTranscriptEdit,
        blacklist: ['SILP']
      },
      {
        name: 'contracting-blueprint',
        sidebar: {
          title: 'Urakointisuunnitelma'
        },
        path: '/kohteet/:id/urakointisuunnitelma',
        exact: true,
        component: EstateContractingBlueprint,
        blacklist: ['SILP']
      },
      {
        name: 'estate-attachments',
        sidebar: {
          title: 'Kuvat ja liitetiedostot'
        },
        path: '/kohteet/:id/liitetiedostot',
        exact: true,
        component: EstateAttachments
      },
      {
        name: 'contracts',
        sidebar: {
          title: 'Sopimukset'
        },
        path: '/kohteet/:id/sopimukset',
        exact: true,
        component: EstateContracts,
        requiredRoles: [roles.admin, roles.editor, roles.salesperson]
      },
      {
        name: 'new-contract',
        path: '/kohteet/:id/sopimukset/luo-uusi',
        exact: true,
        component: NewContractContainer
      },
      {
        name: 'estate-tasks',
        sidebar: {
          title: 'Tehtävät'
        },
        path: '/kohteet/:id/tehtavat',
        exact: true,
        component: EstateTasks,
        requiredRoles: [
          roles.admin,
          roles.editor,
          roles.mechanic,
          roles.salesperson
        ]
      },
      {
        name: 'layout-designer',
        path: '/kohteet/:id/pohjapiirros',
        exact: true,
        component: EstateLayoutDesigner,
        blacklist: ['SILP']
      }
    ]
  },
  {
    path: '/myynti',
    exact: true,
    component: ContractsPage,
    header: {
      title: 'Myynti'
    },
    requiredRoles: [roles.admin, roles.editor, roles.salesperson]
  },
  {
    path: '/myynti/liidit',
    exact: true,
    component: LeadsPage,
    requiredRoles: [roles.admin, roles.editor, roles.salesperson]
  },
  {
    path: '/sopimukset/:id',
    exact: true,
    component: ContractPage,
    requiredRoles: [roles.admin, roles.editor, roles.salesperson]
  },
  {
    path: '/tehtavat',
    exact: true,
    component: TasksPage,
    header: {
      title: 'Tehtävät'
    },
    requiredRoles: [
      roles.admin,
      roles.editor,
      roles.mechanic,
      roles.salesperson
    ]
  },
  {
    path: '/tehtavat/:id',
    exact: true,
    component: TaskPage,
    requiredRoles: [
      roles.admin,
      roles.editor,
      roles.mechanic,
      roles.salesperson
    ]
  },
  {
    path: '/laskutus',
    exact: true,
    component: BillingPage,
    header: {
      title: 'Laskutus'
    },
    requiredRoles: [roles.admin, roles.editor, roles.salesperson]
  },
  {
    path: '/laskutus/:id',
    exact: true,
    component: InvoicePage,
    requiredRoles: [roles.admin, roles.editor, roles.salesperson]
  },

  {
    path: '/raportit',
    exact: true,
    component: Reports,
    header: {
      title: 'Raportit'
    },
    requiredRoles: [roles.admin, roles.editor, roles.salesperson]
  },
  {
    path: '/asiakkaat/:id',
    exact: false,
    component: Client,
    requiredRoles: [roles.admin, roles.editor, roles.salesperson]
  },
  {
    path: '/tarjous',
    exact: true,
    component: Offer
  },
  {
    path: '/tuotteet/:id',
    exact: false,
    component: Product
  },
  {
    path: '/hallitse',
    name: 'manage',
    header: {
      title: 'Hallitse'
    },
    exact: true,
    component: () => <Redirect to="/hallitse/kohteet" />,
    routes: [
      {
        name: 'manage-estates',
        path: '/hallitse/kohteet',
        exact: true,
        component: ManageEstates,
        sidebar: {
          title: 'Kohteet'
        }
      },
      {
        name: 'manage-users',
        path: '/hallitse/kayttajat',
        exact: true,
        component: ManageUsers,
        sidebar: {
          title: 'Käyttäjät'
        },
        requiredRoles: [roles.admin, roles.editor]
      },
      {
        name: 'manage-clients',
        sidebar: {
          title: 'Asiakkaat'
        },
        path: '/hallitse/asiakkaat',
        exact: true,
        component: ManageClients,
        requiredRoles: [roles.admin, roles.editor, roles.salesperson]
      },
      {
        name: 'manage-products',
        sidebar: {
          title: 'Tuotteet'
        },
        path: '/hallitse/tuotteet',
        exact: true,
        component: ManageProducts,
        requiredRoles: [roles.admin, roles.editor, roles.salesperson]
      },
      {
        name: 'manage-energy-companies',
        sidebar: {
          title: 'Energiayhtiöt'
        },
        path: '/hallitse/energiayhtiot',
        exact: true,
        component: ManageEnergyCompanies,
        requiredRoles: [roles.admin, roles.editor, roles.salesperson],
        routes: [
          {
            name: 'energy-company',
            path: '/hallitse/energiayhtiot/:id',
            exact: true,
            component: EnergyCompany,
            requiredRoles: [roles.admin, roles.editor, roles.salesperson]
          }
        ]
      }
    ]
  },
  {
    path: '/hups',
    component: NotFound
  },
  {
    component: NotFound
  }
];
