import { takeEvery, fork, call, put } from 'redux-saga/effects';
import { FETCH_SERVICES, receiveServices } from './actions';
import apiClient from '../../services/api-service';

function* handleFetchServices() {
  try {
    const {
      data: { data: services }
    } = yield call(apiClient.get, '/V1/contract-services', {
      params: { sort: 'weight' }
    });
    yield put(receiveServices(services));
  } catch {}
}

function* handleWatchFetchServices() {
  yield takeEvery(FETCH_SERVICES, handleFetchServices);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchFetchServices);
}
