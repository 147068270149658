import { defaultTo } from 'ramda';
import { createReducer } from '../../utils/redux';
import {
  FETCH_CLIENTS,
  CREATE_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  RECEIVE_CLIENTS,
  FETCH_CLIENT_TYPES,
  RECEIVE_CLIENT_TYPES,
  FETCH_FAILED,
  SHOW_CLIENT_CREATE_MODAL,
  CLOSE_CLIENT_CREATE_MODAL,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_ERROR
} from './actions';
import { pageRootless as selectPage } from './selectors';
import initialState from './state';

export default createReducer(initialState, {
  [FETCH_CLIENTS]: (state, { page }) => {
    const currentPage = defaultTo(selectPage(state), page);
    return {
      ...state,
      fetching: true,
      pagination: {
        ...state.pagination,
        currentPage
      }
    };
  },
  [CREATE_CLIENT]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        clientCreateModal: {
          ...state.ui.clientCreateModal,
          fetching: true
        }
      }
    };
  },
  [RECEIVE_CLIENTS]: (state, { clients, pagination }) => {
    return { ...state, clients, pagination, fetching: false, loaded: true };
  },
  [EDIT_CLIENT]: state => {
    return { ...state, fetching: true };
  },
  [DELETE_CLIENT]: state => {
    return { ...state, fetching: true };
  },
  [FETCH_FAILED]: state => {
    return { ...state, fetching: false, error: true };
  },
  [FETCH_CLIENT_TYPES]: (state, { clientTypes }) => {
    return {
      ...state,
      clientTypes: {
        ...state.clientTypes,
        fetching: true
      }
    };
  },
  [RECEIVE_CLIENT_TYPES]: (state, { clientTypes }) => {
    return {
      ...state,
      clientTypes: {
        ...state.clientTypes,
        loaded: true,
        fetching: false,
        data: clientTypes
      }
    };
  },
  [SHOW_CLIENT_CREATE_MODAL]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        clientCreateModal: { ...state.ui.clientCreateModal, show: true }
      }
    };
  },
  [CLOSE_CLIENT_CREATE_MODAL]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        clientCreateModal: { ...state.ui.clientCreateModal, show: false }
      }
    };
  },
  [CREATE_CLIENT_SUCCESS]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        clientCreateModal: {
          ...state.ui.clientCreateModal,
          fetching: false,
          error: false
        }
      }
    };
  },
  [CREATE_CLIENT_ERROR]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        clientCreateModal: {
          ...state.ui.clientCreateModal,
          fetching: false,
          error: true
        }
      }
    };
  }
});
