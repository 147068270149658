import { createSelector } from 'reselect';
import { pipe, path, prop, head, has, isNil } from 'ramda';
import namespace from './namespace';

const root = path(namespace);

const user = pipe(
  root,
  prop('user')
);

const token = pipe(
  root,
  prop('token')
);

export const fetching = pipe(
  root,
  prop('fetching')
);

export const loaded = pipe(
  root,
  prop('loaded')
);

export const makeUserSelector = () => {
  return createSelector(
    [user],
    user => user
  );
};

export const makeIsAuthenticatedSelector = () => {
  return createSelector(
    [user],
    user => {
      return user != null;
    }
  );
};

export const makeInitialsSelector = () => {
  return createSelector(
    [user],
    user => {
      const hasProperty = (name, obj) =>
        has(name, obj) && !isNil(prop(name, obj));
      if (
        user == null ||
        !hasProperty('firstName', user) ||
        !hasProperty('lastName', user)
      ) {
        return '';
      }
      return `${head(user.firstName)}${head(user.lastName)}`;
    }
  );
};

export const makeCurrentUserSelector = () => {
  return createSelector(
    [user],
    user => {
      const hasProperty = (name, obj) =>
        has(name, obj) && !isNil(prop(name, obj));
      if (
        user == null ||
        !hasProperty('firstName', user) ||
        !hasProperty('lastName', user)
      ) {
        return '';
      }
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        organizations: user.organizations || []
      };
    }
  );
};

export const makeTokenSelector = () => {
  return createSelector(
    [token],
    token => token
  );
};

export const activeOrganization = pipe(
  root,
  prop('activeOrganization')
);
