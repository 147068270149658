import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

/**
 * ProtectedRoute
 *
 * Protected route, cannot be accessed without authentication
 *
 * @param {Component} component
 * @param {boolean} isAuthenticated
 * @param {any} {...rest}
 * @returns {undefined}
 */
const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/kirjaudu',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.oneOfType([
    Route.propTypes.component,
    PropTypes.element,
    PropTypes.func
  ])
};

export default ProtectedRoute;
