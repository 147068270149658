import { createReducer } from '../../utils/redux';
import { FETCH_SERVICES, RECEIVE_SERVICES } from './actions';
import initialState from './state';

export default createReducer(initialState, {
  [FETCH_SERVICES]: state => {
    return { ...state, services: { ...state.services, fetching: true } };
  },
  [RECEIVE_SERVICES]: (state, { services }) => {
    return {
      ...state,
      services: {
        ...state.services,
        fetching: false,
        loaded: true,
        data: services
      }
    };
  }
});
