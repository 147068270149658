import { createActionCreator as action, createType } from '../../utils/redux';
import namespace from './namespace';

const type = createType(namespace);
export const FETCH_USERS = type('FETCH_USERS');
export const fetchUsers = action(FETCH_USERS, 'page');
export const FETCH_USERS_ERROR = type('FETCH_USERS_ERROR');
export const fetchUsersError = action(FETCH_USERS_ERROR, 'error');
export const RECEIVE_USERS = type('RECEIVE_USERS');
export const receiveUsers = action(RECEIVE_USERS, 'users', 'pagination');
export const SHOW_USER_CREATE_MODAL = type('SHOW_USER_CREATE_MODAL');
export const showUserCreateModal = action(SHOW_USER_CREATE_MODAL);
export const CLOSE_USER_CREATE_MODAL = type('CLOSE_USER_CREATE_MODAL');
export const closeUserCreateModal = action(CLOSE_USER_CREATE_MODAL);
export const CREATE_USER = type('CREATE_USER');
export const createUser = action(CREATE_USER, 'user');
export const EDIT_USER = type('EDIT_USER');
export const editUser = action(EDIT_USER, 'user');
export const DELETE_USER = type('DELETE_USER');
export const deleteUser = action(DELETE_USER, 'user');
export const DELETE_USER_ERROR = type('DELETE_USER_ERROR');
export const deleteUserError = action(DELETE_USER_ERROR, 'error');
export const DELETE_USER_SUCCESS = type('DELETE_USER_SUCCESS');
export const deleteUserSuccess = action(DELETE_USER_SUCCESS);
export const CREATE_USER_SUCCESS = type('CREATE_USER_SUCCESS');
export const createUserSuccess = action(CREATE_USER_SUCCESS);
export const CREATE_USER_ERROR = type('CREATE_USER_ERROR');
export const createUserError = action(CREATE_USER_ERROR, 'error');
export const EDIT_USER_SUCCESS = type('EDIT_USER_SUCCESS');
export const editUserSuccess = action(EDIT_USER_SUCCESS);
export const EDIT_USER_ERROR = type('EDIT_USER_ERROR');
export const editUserError = action(EDIT_USER_ERROR, 'error');
