import {
  takeEvery,
  fork,
  put,
  call,
  select,
  takeLatest
} from 'redux-saga/effects';
import {
  FETCH_CONTRACTS_BY_STATE,
  SET_CONTRACTS_BY_STATE_QUERY,
  receiveContractsByState,
  fetchContractsByState,
  fetchContractsByStateError,
  setContractsByStatePagination,
  FETCH_CONTRACT_TYPES,
  fetchContractTypesError,
  receiveContractTypes,
  FETCH_CONTRACTS_BY_ESTATE,
  receiveContractsByEstate,
  fetchContractsByEstateError
} from './actions';
import { makeGetContractsByStateSelector } from './selectors';

import api from '../../services/api-service';
import { normalize } from '../../utils/response';

function* handleFetchContractsByState({ state }) {
  const contractsSelector = makeGetContractsByStateSelector(state);
  const {
    pagination: { currentPage: page },
    query,
    qs = ''
  } = yield select(contractsSelector);

  try {
    const {
      data: {
        data,
        meta: { pagination }
      }
    } = yield call(api.get, `/V1/contracts?${qs}`, {
      params: {
        page,
        ...query
      }
    });
    yield put(receiveContractsByState(state, normalize(data), pagination));
  } catch (e) {
    yield put(fetchContractsByStateError(state, e));
  }
}

function* handleWatchFetchContractsByState() {
  yield takeEvery(FETCH_CONTRACTS_BY_STATE, handleFetchContractsByState);
}

function* handleSetContractsByStateQuery({ state, query }) {
  yield put(setContractsByStatePagination(state, 1));
  yield put(fetchContractsByState(state));
}

function* handleWatchSetContractsByStateQuery() {
  yield takeLatest(
    SET_CONTRACTS_BY_STATE_QUERY,
    handleSetContractsByStateQuery
  );
}

function* handleFetchContractTypes() {
  try {
    const {
      data: { data }
    } = yield call(api.get, '/V1/contract-types');
    yield put(receiveContractTypes(data));
  } catch (e) {
    yield put(fetchContractTypesError());
  }
}

function* handleWatchFetchContractTypes() {
  yield takeLatest(FETCH_CONTRACT_TYPES, handleFetchContractTypes);
}

// estate
function* handleFetchContractsByEstate({ id }) {
  try {
    const {
      data: { data }
    } = yield call(api.get, `/V1/contracts`, {
      params: {
        // TODO include invoices
        include: 'client,user,contractType,pdf',
        estateId: id,
        // There won't probably ever be this many contracts for single estate
        _limit: 100
      }
    });
    yield put(receiveContractsByEstate(id, normalize(data)));
  } catch (e) {
    yield put(fetchContractsByEstateError(id, e));
  }
}

function* handleWatchFetchContractsByEstate() {
  yield takeLatest(FETCH_CONTRACTS_BY_ESTATE, handleFetchContractsByEstate);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchFetchContractsByState);
  yield fork(handleWatchSetContractsByStateQuery);
  yield fork(handleWatchFetchContractTypes);
  yield fork(handleWatchFetchContractsByEstate);
}
