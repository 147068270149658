import { createActionCreator as action, createType } from '../../utils/redux';
import namespace from './namespace';

const type = createType(namespace);
export const FETCH_ROLES = type('FETCH_ROLES');
export const fetchRoles = action(FETCH_ROLES);
export const RECEIVE_ROLES = type('RECEIVE_ROLES');
export const receiveRoles = action(RECEIVE_ROLES, 'roles');
export const FETCH_ROLES_FAILED = type('FETCH_ROLES_FAILED');
export const fetchRolesFailed = action(FETCH_ROLES_FAILED, 'error');
