import Cookie from 'js-cookie';
import {
  takeEvery,
  takeLatest,
  fork,
  put,
  call,
  select,
  delay
} from 'redux-saga/effects';
import {
  RECEIVE_USER,
  SET_USER_TOKEN,
  LOGIN,
  LOGIN_FAILED,
  FETCH_USER,
  LOGOUT,
  CLEAR_USER,
  SET_ACTIVE_ORGANIZATION
} from './actions';
import { makeIsAuthenticatedSelector, makeTokenSelector } from './selectors';
import { reload, navigate } from '../navigation/actions';
import {
  over,
  lensProp,
  partial,
  head,
  prop,
  propEq,
  find,
  isNil,
  pipe,
  propOr
} from 'ramda';
import { addMinutes as addMinutes_ } from 'date-fns';
import client from '../../services/api-service';
import { shouldRefreshToken } from '../../utils/auth';
import { normalize } from '../../utils/response';

export function* handleLogin({ email, password }) {
  const info = { email, password };
  try {
    const { data: result } = yield call(client.post, '/V1/auth/login', info);
    const addMinutes = partial(addMinutes_, [new Date()]);
    const token = over(
      lensProp('expires_in'),
      pipe(
        addMinutes,
        d => d.toJSON()
      ),
      result
    );

    yield put({ type: SET_USER_TOKEN, token });
    yield put({ type: FETCH_USER });
  } catch {
    yield put({ type: LOGIN_FAILED });
  }
}

function* handleWatchLogin() {
  yield takeEvery(LOGIN, handleLogin);
}

export function* handleFetchUser() {
  /**
   * NOTE: We cannot include roles here as the /me route does not care about active organization
   *
   */
  try {
    const {
      data: { data }
    } = yield call(client.get, '/V1/me', {
      params: {
        include: 'organizations'
      }
    });
    const user = normalize(data);

    const activeOrganizationCookie = Cookie.get('active-organization');
    // Get the organization from cookies, or pick the first one
    const currentOrganization =
      activeOrganizationCookie &&
      !isNil(find(propEq('id', activeOrganizationCookie), user.organizations))
        ? find(propEq('id', activeOrganizationCookie), user.organizations)
        : head(user.organizations);

    yield put({
      type: SET_ACTIVE_ORGANIZATION,
      organization: currentOrganization,
      forceReload: false
    });

    // NOTE: we fetch the roles from /V1/users because the roles are there scoped by the active organization
    const {
      data: { data: userWithRoleData }
    } = yield call(client.get, `/V1/users/${user.id}`, {
      params: {
        include: 'roles'
      }
    });
    const roles = propOr([], 'roles', normalize(userWithRoleData));

    // Append the roles under user data
    const userWithRoles = {
      ...user,
      roles
    };

    yield put({ type: RECEIVE_USER, user: userWithRoles });
  } catch {
    // If the request fails set user as empty, so the app won't try to 're-login' automatically
    yield put({ type: RECEIVE_USER, user: null });
  }
}

function* watchHandleFetchUser() {
  yield takeLatest(FETCH_USER, handleFetchUser);
}

function* handleLogout() {
  try {
    yield call(client.post, 'V1/auth/logout');
    yield put(navigate('/kirjaudu'));
  } catch (e) {
    // todo handle lgoout error
  }
}

function* handleWatchLogout() {
  yield takeLatest(LOGOUT, handleLogout);
}

// On how often we should check the token expiration
const minutes = 5;
// const minutes = 5;
export const INTERVAL = 60000 * minutes;
export function* handleCheckUserTokenExpiration() {
  const isAuthenticatedSelector = makeIsAuthenticatedSelector();
  const isAuthenticated = yield select(isAuthenticatedSelector);

  const tokenSelector = makeTokenSelector();
  const token = yield select(tokenSelector);

  if (isAuthenticated && shouldRefreshToken(token)) {
    try {
      const { data: result } = yield call(client.post, 'V1/auth/refresh/');

      const addMinutes = partial(addMinutes_, [new Date()]);
      const token = over(
        lensProp('expires_in'),
        pipe(
          addMinutes,
          d => d.toJSON()
        ),
        result
      );

      yield put({ type: SET_USER_TOKEN, token });
    } catch (e) {
      // unable to refresh token
      yield put({ type: CLEAR_USER });
    }
  }

  // Delay the execution
  yield delay(INTERVAL);
}

function* handleWatchCheckUserTokenExpiration() {
  // Check token expiration
  while (true) {
    yield call(handleCheckUserTokenExpiration);
  }
}

// eslint-disable-next-line
function* handleSetActiveOrganization({
  organization,
  forceReload = true,
  preserveCurrentUrl = false
}) {
  Cookie.set('active-organization', prop('id', organization));

  if (forceReload) {
    if (!preserveCurrentUrl) {
      yield put(navigate('/'));
    }
    yield put(reload());
  }
}

function* handleWatchSetActiveOrganization() {
  yield takeLatest(SET_ACTIVE_ORGANIZATION, handleSetActiveOrganization);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchLogin);
  yield fork(watchHandleFetchUser);
  yield fork(handleWatchLogout);
  yield fork(handleWatchCheckUserTokenExpiration);
  yield fork(handleWatchSetActiveOrganization);
}
