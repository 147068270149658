import { createSlice } from 'redux-starter-kit';
import { editableEntity } from 'utils/store';

export default createSlice({
  slice: 'contract',
  initialState: {
    ...editableEntity.initialState
  },
  reducers: {
    ...editableEntity.reducers
  }
});
