import { includes, propOr, prop } from 'ramda';

export const admin = 'admin';
export const editor = 'editor';
export const salesperson = 'salesperson';
export const mechanic = 'mechanic';
export const vendor = 'vendor';
export const electricUtility = 'electricUtility';

export const roles = {
  admin,
  editor,
  salesperson,
  mechanic,
  vendor,
  electricUtility
};

const hasRole = (roleName, u) => {
  const usersRoles = propOr([], 'roles', u).map(r => prop('name', r));
  return includes(roleName, usersRoles);
};

export const isAdmin = u => hasRole(admin, u);
export const isEditor = u => hasRole(editor, u);
export const isSalesperson = u => hasRole(salesperson, u);
