/* Because React-router doesn't provide us any way to access the 'history' (https://reacttraining.com/react-router/web/api/history) component
 without wrapping the component inside 'withRouter' (https://reacttraining.com/react-router/web/api/withRouter) we cannot trigger navigation outside the components.

 This enables us to navigate programmatically (e.g Create redirection from saga)
 FROM: https://stackoverflow.com/a/49973624
 */

// History is already a dependency of react-router-dom (https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/package.json)
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;
