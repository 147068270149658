import { useSelector, useDispatch } from 'react-redux';
import { setActiveOrganization, logout, login } from 'modules/user/actions';

export const useAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector(s => s.user.user);
  const organization = useSelector(s => s.user.activeOrganization);

  return {
    user,
    organization,
    setActiveOrganization: (...args) =>
      dispatch(setActiveOrganization(...args)),
    tryToSetActiveOrganizationByIdInCurrentUrl: id => {
      const newOrganization = user.organizations.find(o => o.id === id);
      if (newOrganization && newOrganization.id !== organization.id) {
        dispatch(setActiveOrganization(newOrganization, true, true));
      }
    },
    logout: () => dispatch(logout()),
    login: (...args) => dispatch(login(...args))
  };
};
