import { createSelector } from 'reselect';
import { pipe, path, prop } from 'ramda';
import namespace from './namespace';

const root = path(namespace);

const estates = pipe(
  root,
  prop('estates')
);

export const getPagination = pipe(
  root,
  prop('pagination')
);

export const makeGetEstatesSelector = () => {
  return createSelector(
    [estates],
    estates => estates
  );
};

export const estateTypes = pipe(
  root,
  prop('estateTypes')
);

const leadsUnwrapped = pipe(
  root,
  prop('leads')
);

export const leads = pipe(
  leadsUnwrapped,
  prop('data')
);

export const leadsLoaded = pipe(
  leadsUnwrapped,
  prop('loaded')
);

export const leadsFetching = pipe(
  leadsUnwrapped,
  prop('fetching')
);

export const leadsError = pipe(
  leadsUnwrapped,
  prop('error')
);

export const leadsPagination = pipe(
  leadsUnwrapped,
  prop('pagination')
);

export const leadsQuery = pipe(
  leadsUnwrapped,
  prop('query')
);

export const isFetching = pipe(
  root,
  prop('fetching')
);

export const isLoaded = pipe(
  root,
  prop('loaded')
);

export const estate = pipe(
  root,
  prop('estate')
);

export const estateLoaded = pipe(
  estate,
  prop('loaded')
);

export const estateFetching = pipe(
  estate,
  prop('fetching')
);

export const estateData = pipe(
  estate,
  prop('data')
);

export const estateAttachments = pipe(
  estate,
  prop('attachments')
);

export const equipmentSurveyTranscript = pipe(
  estate,
  prop('equipmentSurveyTranscript')
);

export const ui = pipe(
  root,
  prop('ui')
);
