import { takeLatest, fork, put, call, select } from 'redux-saga/effects';
import {
  FETCH_ESTATES,
  fetchEstates,
  fetchEstatesFailed,
  receiveEstates,
  FETCH_ESTATE,
  FETCH_ESTATE_FAILED,
  SET_ESTATES_QUERY,
  setEstatesPagination,
  RECEIVE_ESTATE,
  SAVE_ESTATE_LAYOUT,
  SAVE_ESTATE_LAYOUT_FAILED,
  FETCH_LEADS,
  fetchLeads,
  receiveLeads,
  fetchLeadsFailed,
  // FETCH_ESTATE_TYPES,
  // receiveEstateTypes,
  FETCH_ESTATE_ATTACHMENTS,
  fetchEstateAttachments,
  CREATE_ESTATE_ATTACHMENT,
  fetchEstateAttachmentsFailed,
  receiveEstateAttachments,
  FETCH_EQUIPMENT_SURVEY_TRANSCRIPT,
  fetchEquipmentSurveyTranscriptFailed,
  receiveEquipmentSurveyTranscript,
  EDIT_EQUIPMENT_SURVEY_TRANSCRIPT,
  editEquipmentSurveyTranscriptSuccess,
  editEquipmentSurveyTranscriptFailed,
  SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL,
  sendEquipmentSurveyTranscriptToEmailSuccess,
  sendEquipmentSurveyTranscriptToEmailFailed,
  fetchEquipmentSurveyTranscript,
  SET_LEADS_QUERY,
  setLeadsPagination
} from './actions';
import { navigate } from '../navigation/actions';
import {
  leadsPagination,
  leadsQuery,
  makeGetEstatesSelector
} from './selectors';
import api from '../../services/api-service';
import { normalize } from '../../utils/response';

function* handleFetchEstates() {
  const getEstates = makeGetEstatesSelector();
  const {
    pagination: { currentPage: page },
    query
  } = yield select(getEstates);
  try {
    const {
      data: {
        data,
        meta: { pagination }
      }
    } = yield call(api.get, '/V1/estates', {
      params: {
        include: 'client,user,comments',
        page,
        ...query
      }
    });
    yield put(receiveEstates(normalize(data), pagination));
  } catch (e) {
    yield put(fetchEstatesFailed(e));
  }
}

function* handleWatchFetchEstates() {
  yield takeLatest(FETCH_ESTATES, handleFetchEstates);
}

function* handleSetEstatesQuery({ query }) {
  // TODO: should preveserve pagination if possible
  yield put(setEstatesPagination(1));
  yield put(fetchEstates());
}

function* handleWatchSetEstatesQuery() {
  yield takeLatest(SET_ESTATES_QUERY, handleSetEstatesQuery);
}

/**
 * Single estate
 *
 */

function* handleFetchEstate({ id, include = [] }) {
  try {
    const {
      data: { data: estate }
    } = yield call(api.get, `/V1/estates/${id}`, {
      params: {
        include: [
          'user',
          'client',
          'layout',
          'measurement',
          'technical',
          'schematic',
          'contractingBlueprint',
          'energyCompany',
          'janitor',
          'chairman',
          ...include
        ].join(',')
      }
    });

    yield put({ type: RECEIVE_ESTATE, estate: normalize(estate) });
  } catch {
    yield put({ type: FETCH_ESTATE_FAILED });
    yield put(navigate('/hups'));
  }
}

function* handleWatchFetchEstate() {
  yield takeLatest(FETCH_ESTATE, handleFetchEstate);
}

function* handleSaveEstateLayout({ id, layout }) {
  try {
    yield call(api.post, `/V1/estates/${id}/layout`, {
      ...layout
    });
    yield put({ type: FETCH_ESTATE, id });
  } catch {
    yield put({ type: SAVE_ESTATE_LAYOUT_FAILED });
  }
}

function* handleWatchSaveEstateLayout() {
  yield takeLatest(SAVE_ESTATE_LAYOUT, handleSaveEstateLayout);
}

function* handleFetchEstateAttachments({ id }) {
  try {
    const {
      data: { data: attachments }
    } = yield call(api.get, `/V1/estates/${id}/attachments`);
    yield put(receiveEstateAttachments(normalize(attachments)));
  } catch {
    yield put(fetchEstateAttachmentsFailed);
    yield put(navigate('/hups'));
  }
}

function* handleWatchFetchEstateAttachments() {
  yield takeLatest(FETCH_ESTATE_ATTACHMENTS, handleFetchEstateAttachments);
}

function* handleFetchEquipmentSurveyTranscript({ id }) {
  try {
    const {
      data: { data: equipmentSurveyTranscript }
    } = yield call(
      api.get,
      `V1/estates/${id}/equipment-survey-transcript?include=user,pdf`
    );
    yield put(
      receiveEquipmentSurveyTranscript(normalize(equipmentSurveyTranscript))
    );
  } catch (e) {
    // TODO check for 404
    // The eqs won't probalby exist if error is thrown
    // yield put(navigate('/hups'));
    yield put(fetchEquipmentSurveyTranscriptFailed());
  }
}

function* handleCreateEstateAttachment({ id, attachments }) {
  try {
    yield call(api.post, `V1/estates/${id}/attachments`, attachments);
    yield put(fetchEstateAttachments(id));
  } catch (e) {
    // TODO check for 404
    // The eqs won't probalby exist if error is thrown
    // yield put(navigate('/hups'));
    // yield put(fetchEquipmentSurveyTranscriptFailed());
  }
}

function* handleWatchCreateEstateAttachment() {
  yield takeLatest(CREATE_ESTATE_ATTACHMENT, handleCreateEstateAttachment);
}

function* handleWatchFetchEquipmentSurveyTranscript() {
  yield takeLatest(
    FETCH_EQUIPMENT_SURVEY_TRANSCRIPT,
    handleFetchEquipmentSurveyTranscript
  );
}

function* handleEditEquipmentSurveyTranscript({
  id,
  equipmentSurveyTranscript
}) {
  try {
    yield call(
      api.patch,
      `V1/estates/${id}/equipment-survey-transcript`,
      equipmentSurveyTranscript
    );
    yield put(fetchEquipmentSurveyTranscript(id));
    // We need to also fetch the current estate attachments so that our UI will show the correct files
    yield put(fetchEstateAttachments(id));
    yield put(editEquipmentSurveyTranscriptSuccess());
  } catch (e) {
    yield put(editEquipmentSurveyTranscriptFailed());
  }
}

function* handleWatchEditEquipmentSurveyTranscript() {
  yield takeLatest(
    EDIT_EQUIPMENT_SURVEY_TRANSCRIPT,
    handleEditEquipmentSurveyTranscript
  );
}

function* handleSendEquipmentSurveyTranscriptToEmail({ id }) {
  try {
    yield call(api.post, `V1/estates/${id}/equipment-survey-transcript/send`);
    yield put(sendEquipmentSurveyTranscriptToEmailSuccess());
    yield put(fetchEquipmentSurveyTranscript(id));
  } catch (e) {
    yield put(sendEquipmentSurveyTranscriptToEmailFailed());
  }
}

function* handleWatchSendEquipmentSurveyTranscriptToEmail() {
  yield takeLatest(
    SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL,
    handleSendEquipmentSurveyTranscriptToEmail
  );
}

function* handleFetchLeads() {
  const { currentPage: page } = yield select(leadsPagination);
  const query = yield select(leadsQuery);
  try {
    const {
      data: {
        data,
        meta: { pagination }
      }
    } = yield call(api.get, '/V1/estates/leads?include=client,user', {
      params: {
        include: 'client',
        page,
        ...query
      }
    });
    yield put(receiveLeads(normalize(data), pagination));
  } catch (e) {
    yield put(fetchLeadsFailed(e));
  }
}

function* handleWatchFetchLeads() {
  yield takeLatest(FETCH_LEADS, handleFetchLeads);
}

function* handleWatchFetchEstateTypes() {
  // yield take(FETCH_ESTATE_TYPES);
  // try {
  //   const {
  //     data: { data: esatateTypes }
  //   } = yield call(api.get, '/V1/estate-types');
  //   yield put(receiveEstateTypes(esatateTypes));
  // } catch {
  //   yield put({ type: FETCH_FAILED });
  // }
}

function* handleSetLeadsQuery({ query }) {
  // TODO: should preveserve pagination if possible
  yield put(setLeadsPagination(1));
  yield put(fetchLeads());
}

function* handleWatchSetLeadsQuery() {
  yield takeLatest(SET_LEADS_QUERY, handleSetLeadsQuery);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchFetchEstates);
  yield fork(handleWatchSetEstatesQuery);
  yield fork(handleWatchFetchEstate);
  yield fork(handleWatchSaveEstateLayout);
  yield fork(handleWatchFetchEstateAttachments);
  yield fork(handleWatchCreateEstateAttachment);
  yield fork(handleWatchFetchEquipmentSurveyTranscript);
  yield fork(handleWatchEditEquipmentSurveyTranscript);
  yield fork(handleWatchSendEquipmentSurveyTranscriptToEmail);
  yield fork(handleWatchFetchLeads);
  yield fork(handleWatchFetchEstateTypes);
  yield fork(handleWatchSetLeadsQuery);
}
