export default {
  clientTypes: {
    data: [],
    fetching: false,
    loaded: false
  },
  clients: [],
  fetching: false,
  loaded: false,
  error: false,
  pagination: {
    count: 1,
    currentPage: 1,
    total: 1,
    totalPages: 1
  },
  ui: {
    clientCreateModal: {
      show: false,
      fetching: false,
      error: false
    }
  }
};
