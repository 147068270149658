import axios from 'axios';
import Cookie from 'js-cookie';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': `application/json`
  }
});

// Add additional download method which sets the correct responseType
apiClient.download = url => {
  return apiClient.get(url, {
    responseType: 'blob'
  });
};

// export request and response interceptors so they can be used
// to handle redirections etc in other parts of the application (in case of errors)
export const registerRequestInterceptor = (
  requestInterceptor,
  errorInterceptor
) => {
  return apiClient.interceptors.request.use(
    requestInterceptor,
    errorInterceptor
  );
};

export const registerResponseInterceptor = (
  responseInterceptor,
  errorInterceptor
) => {
  return apiClient.interceptors.response.use(
    responseInterceptor,
    errorInterceptor
  );
};

// Add authorization header to request automatically from cookies (if they are present)
registerRequestInterceptor(
  request => {
    const apiToken = Cookie.get('api_token');
    // Add auth headers to request if they exist
    if (!!apiToken) {
      request.headers.Authorization = `Bearer ${apiToken}`;
    }

    const activeOrganization = Cookie.get('active-organization');
    if (!!activeOrganization) {
      request.headers['active-organization'] = activeOrganization;
    }

    return request;
  },
  error => Promise.reject(error)
);

export default apiClient;
