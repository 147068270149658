export default {
  estates: {
    fetching: false,
    loaded: false,
    error: false,
    data: [],
    query: {
      sort: '-created_at'
    },
    pagination: {
      count: 1,
      currentPage: 1,
      total: 1,
      totalPages: 1
    }
  },
  estateTypes: [],

  estate: {
    loaded: false,
    fetching: false,
    data: null,

    // attachments
    attachments: {
      loaded: false,
      fetching: false,
      data: []
    },
    // Equipment survey transcript
    equipmentSurveyTranscript: {
      loaded: false,
      fetching: false,
      data: null
    }
  },
  leads: {
    data: [],
    loaded: false,
    fetching: false,
    error: null,
    query: {
      sort: '-created_at',
      include: 'client,user,equipmentSurveyTranscript'
    },
    pagination: {
      count: 0,
      currentPage: 1,
      total: 0,
      totalPages: 1
    }
  },
  ui: {
    editEquipmentSurveyTranscriptForm: {
      fetching: false,
      error: false
    },
    sendEquipmentSurveyTranscriptToEmailForm: {
      fetching: false,
      error: false,
      success: false
    },
    createEstateModal: {
      show: false
    }
  }
};
