import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import { useNotifications } from 'hooks/notifications';

const Notifications = props => {
  const { notifications, remove } = useNotifications();
  const dispatch = useDispatch();

  if (!notifications.length) return null;

  return (
    <div className="Notifications">
      {notifications.map(n => (
        <Alert
          dismissible
          className={n.onClickAction ? 'alert-action' : ''}
          variant={n.variant || 'primary'}
          key={n.id}
          onClick={() => n.onClickAction && dispatch(n.onClickAction)}
          onClose={() => remove(n.id)}
        >
          {n.content}
        </Alert>
      ))}
    </div>
  );
};

export default Notifications;
