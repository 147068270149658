import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { isEmpty, pick } from 'ramda';
import { ThemeProvider } from 'styled-components';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fi from 'date-fns/esm/locale/fi';
import theme from './constants/theme';
import * as serviceWorker from './serviceWorker';
import rootSaga from './modules/sagas.js';
import rootReducer from './modules/reducers.js';
import initBugsnagClient from './services/bugsnag';
import ErrorBoundaryComp from './components/error-boundary';
import thunk from 'redux-thunk';
import { setLocale as setYupLocale } from 'yup';

import { clearUser } from './modules/user/actions';
import { makeUserSelector } from './modules/user/selectors';

import { actions as NotificationActions } from 'modules/notifications';

import api, {
  registerResponseInterceptor as responseMiddleware
} from './services/api-service';

import AppRoot from 'components/AppRoot';
import 'styles/index.scss';

global.__locale__ = 'fi';
registerLocale('fi', fi);
setDefaultLocale('fi');

setYupLocale({
  mixed: {
    default: 'Syötä kelvollinen arvo',
    required: 'Pakollinen kenttä'
  },
  string: {
    email: 'Syötä kelvollinen sähköposti'
  }
});

// Bugsnag
const beforeSendMiddleware = report => {
  const user = makeUserSelector(store.getState())(store.getState());

  // If we have user available, send only the users id to bugsnag
  if (!!user) {
    const filteredUser = pick(
      ['id'],
      makeUserSelector(store.getState())(store.getState())
    );
    report.user = filteredUser;
  }
};

const bugsnagClient = initBugsnagClient({
  beforeSend: beforeSendMiddleware
});

// Use the bugsnag provided react plugin (if bugsnag is available)
const ErrorBoundary = !isEmpty(bugsnagClient)
  ? bugsnagClient.getPlugin('react')
  : ErrorBoundaryComp;

// Configure redux store and saga
const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    // Notify unhandled errors from saga
    if (!isEmpty(bugsnagClient)) {
      bugsnagClient.notify(error, { metaData: { sagaStack } });
    }
    // Show errors in the console
    console.error(error);
    console.error(sagaStack);
    throw error;
  }
});

// We inject all io as the last argument for thunks
const io = { api };

// Redux store
const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunk.withExtraArgument(io),
    ...getDefaultMiddleware({ thunk: false }),
    sagaMiddleware
  ]
});

sagaMiddleware.run(rootSaga);

// Catch unauthorized responses
responseMiddleware(
  response => response,
  error => {
    const { response } = error;

    // logout
    if (!!response && response.status === 401) {
      // Clear user from store.
      // Our main routes are protected so user must login to access those
      store.dispatch(clearUser());
    }

    // Catch authorization errors
    if (!!response && response.status === 403) {
      store.dispatch(
        NotificationActions.add({
          id: 'unauthorizedNotification',
          variant: 'danger',
          content: 'Ei käyttöoikeutta.'
        })
      );
    }

    throw error;
  }
);

// Mount the application
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRoot />
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
