import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Routes from '../../routes';
import {
  makeIsAuthenticatedSelector,
  makeUserSelector,
  activeOrganization,
  loaded as userLoaded,
  fetching as userFetching
} from '../../modules/user/selectors';
import { fetchUser } from '../../modules/user/actions';

class RouteContainer extends Component {
  render() {
    const {
      isAuthenticated,
      userLoaded,
      fetchUser,
      user,
      activeOrganization,
      userFetching
    } = this.props;
    return (
      <Routes
        fetchUser={fetchUser}
        userLoaded={userLoaded}
        userFetching={userFetching}
        isAuthenticated={isAuthenticated}
        user={user}
        activeOrganization={activeOrganization}
      />
    );
  }
}

RouteContainer.propTypes = {
  isAuthenticated: PropTypes.bool,
  userLoaded: PropTypes.bool,
  fetchUser: PropTypes.func,
  user: PropTypes.shape({}),
  activeOrganization: PropTypes.shape({})
};

const mapStateToProps = state => {
  const isAuthenticated = makeIsAuthenticatedSelector();
  const userSelector = makeUserSelector();
  return {
    isAuthenticated: isAuthenticated(state),
    userLoaded: userLoaded(state),
    userFetching: userFetching(state),
    user: userSelector(state),
    activeOrganization: activeOrganization(state)
  };
};

export default connect(
  mapStateToProps,
  { fetchUser }
)(RouteContainer);
