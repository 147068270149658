import { createActionCreator as action, createType } from '../../utils/redux';
import namespace from './namespace';

const type = createType(namespace);
export const FETCH_ESTATES = type('FETCH_ESTATES');
export const fetchEstates = action(FETCH_ESTATES);
export const RECEIVE_ESTATES = type('RECEIVE_ESTATES');
export const receiveEstates = action(RECEIVE_ESTATES, 'data', 'pagination');
export const FETCH_ESTATES_FAILED = type('FETCH_ESTATES_FAILED');
export const fetchEstatesFailed = action(FETCH_ESTATES_FAILED, 'error');
export const SET_ESTATES_QUERY = type('SET_ESTATES_QUERY');
export const setEstatesQuery = action(SET_ESTATES_QUERY, 'query');
export const SET_ESTATES_PAGINATION = type('SET_ESTATES_PAGINATION');
export const setEstatesPagination = action(SET_ESTATES_PAGINATION, 'page');

// Leads

export const FETCH_LEADS = type('FETCH_LEADS');
export const fetchLeads = action(FETCH_LEADS);

export const RECEIVE_LEADS = type('RECEIVE_LEADS');
export const receiveLeads = action(RECEIVE_LEADS, 'data', 'pagination');

export const FETCH_LEADS_FAILED = type('FETCH_LEADS_FAILED');
export const fetchLeadsFailed = action(FETCH_LEADS_FAILED, 'error');

export const FETCH_ESTATE_TYPES = type('FETCH_ESTATE_TYPES');
export const fetchEstateTypes = action(FETCH_ESTATE_TYPES);

export const RECEIVE_ESTATE_TYPES = type('RECEIVE_ESTATE_TYPES');
export const receiveEstateTypes = action(RECEIVE_ESTATE_TYPES, 'estateTypes');

export const SET_LEADS_QUERY = type('SET_LEADS_QUERY');
export const setLeadsQuery = action(SET_LEADS_QUERY, 'query');

export const SET_LEADS_PAGINATION = type('SET_LEADS_PAGINATION');
export const setLeadsPagination = action(SET_LEADS_PAGINATION, 'page');

// Single estate
export const FETCH_ESTATE = type('FETCH_ESTATE');
export const fetchEstate = action(FETCH_ESTATE, 'id', 'include');

export const RECEIVE_ESTATE = type('RECEIVE_ESTATE');
export const receiveEstate = action(RECEIVE_ESTATE, 'estate');

export const FETCH_ESTATE_FAILED = type('FETCH_ESTATE_FAILED');
export const fetchEstateFailed = action(FETCH_ESTATE_FAILED, 'error');

// Single estate - layout
export const SAVE_ESTATE_LAYOUT = type('SAVE_ESTATE_LAYOUT');
export const saveEstateLayout = action(SAVE_ESTATE_LAYOUT, 'id', 'layout');

export const SAVE_ESTATE_LAYOUT_FAILED = type('SAVE_ESTATE_LAYOUT_FAILED');
export const saveEstateLayoutFailed = action(
  SAVE_ESTATE_LAYOUT_FAILED,
  'error'
);

// Single estate equipment-survey-transcript
export const FETCH_ESTATE_ATTACHMENTS = type('FETCH_ESTATE_ATTACHMENTS');
export const fetchEstateAttachments = action(FETCH_ESTATE_ATTACHMENTS, 'id');

export const RECEIVE_ESTATE_ATTACHMENTS = type('RECEIVE_ESTATE_ATTACHMENTS');
export const receiveEstateAttachments = action(
  RECEIVE_ESTATE_ATTACHMENTS,
  'attachments'
);
export const FETCH_ESTATE_ATTACHMENTS_FAILED = type(
  'FETCH_ESTATE_ATTACHMENTS_FAILED'
);
export const fetchEstateAttachmentsFailed = action(
  FETCH_ESTATE_ATTACHMENTS_FAILED
);

export const CREATE_ESTATE_ATTACHMENT = type('CREATE_ESTATE_ATTACHMENT');
export const createEstateAttachment = action(
  CREATE_ESTATE_ATTACHMENT,
  'id',
  'attachments'
);

export const CREATE_ESTATE_ATTACHMENT_FAILED = type(
  'CREATE_ESTATE_ATTACHMENT_FAILED'
);
export const createEstateAttachmentFailed = action(
  CREATE_ESTATE_ATTACHMENT_FAILED,
  'error'
);

// Removes estate attachemnt from store
export const DELETE_ESTATE_ATTACHMENT_FROM_STORE = type(
  'DELETE_ESTATE_ATTACHMENT_FROM_STORE'
);
export const deleteEstateAttachmentFromStore = action(
  DELETE_ESTATE_ATTACHMENT_FROM_STORE,
  'id'
);

export const CLEAR_ESTATE_ATTACHMENTS = type('CLEAR_ESTATE_ATTACHMENTS');
export const clearEstateAttachments = action(CLEAR_ESTATE_ATTACHMENTS);

// equipment survey transcript
// fetch
export const FETCH_EQUIPMENT_SURVEY_TRANSCRIPT = type(
  'FETCH_EQUIPMENT_SURVEY_TRANSCRIPT'
);
export const fetchEquipmentSurveyTranscript = action(
  FETCH_EQUIPMENT_SURVEY_TRANSCRIPT,
  'id'
);
export const FETCH_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED = type(
  'FETCH_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED'
);
export const fetchEquipmentSurveyTranscriptFailed = action(
  FETCH_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED,
  'id'
);

// receive
export const RECEIVE_EQUIPMENT_SURVEY_TRANSCRIPT = type(
  'RECEIVE_EQUIPMENT_SURVEY_TRANSCRIPT'
);
export const receiveEquipmentSurveyTranscript = action(
  RECEIVE_EQUIPMENT_SURVEY_TRANSCRIPT,
  'equipmentSurveyTranscript'
);

export const EDIT_EQUIPMENT_SURVEY_TRANSCRIPT = type(
  'EDIT_EQUIPMENT_SURVEY_TRANSCRIPT'
);
export const editEquipmentSurveyTranscript = action(
  EDIT_EQUIPMENT_SURVEY_TRANSCRIPT,
  'id',
  'equipmentSurveyTranscript'
);

export const EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_SUCCESS = type(
  'EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_SUCCESS'
);
export const editEquipmentSurveyTranscriptSuccess = action(
  EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_SUCCESS,
  'equipmentSurveyTranscript'
);

export const EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED = type(
  'EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED'
);
export const editEquipmentSurveyTranscriptFailed = action(
  EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED,
  'equipmentSurveyTranscript'
);

export const SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL = type(
  'SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL'
);
export const sendEquipmentSurveyTranscriptToEmail = action(
  SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL,
  'id'
);
export const SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_SUCCESS = type(
  'SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_SUCCESS'
);
export const sendEquipmentSurveyTranscriptToEmailSuccess = action(
  SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_SUCCESS,
  'id'
);
export const SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_FAILED = type(
  'SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_FAILED'
);
export const sendEquipmentSurveyTranscriptToEmailFailed = action(
  SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_FAILED
);

export const CLEAR_EQUIPMENT_SURVEY_TRANSCRIPT = type(
  'CLEAR_EQUIPMENT_SURVEY_TRANSCRIPT'
);
export const clearEquipmentSurveyTranscript = action(
  CLEAR_EQUIPMENT_SURVEY_TRANSCRIPT
);

// Single estate - contractin blueprint
// This 'blueprint' consists of the actual ContractingBlueprint entity, along with the estates files (measurement, schematic, layout)

export const FETCH_ESTATE_CONTRACTING_BLUEPRINT = type(
  'FETCH_ESTATE_CONTRACTING_BLUEPRINT'
);
export const fetchEstateContractingBlueprint = action(
  FETCH_ESTATE_CONTRACTING_BLUEPRINT,
  'id'
);
export const RECEIVE_ESTATE_CONTRACTING_BLUEPRINT = type(
  'RECEIVE_ESTATE_CONTRACTING_BLUEPRINT'
);
export const receiveEstateContractingBlueprint = action(
  RECEIVE_ESTATE_CONTRACTING_BLUEPRINT,
  'contractingBlueprint'
);
export const FETCH_ESTATE_CONTRACTING_BLUEPRINT_FAILED = type(
  'FETCH_ESTATE_CONTRACTING_BLUEPRINT_FAILED'
);
export const fetchEstateContractingBlueprintFailed = action(
  FETCH_ESTATE_CONTRACTING_BLUEPRINT_FAILED,
  'error'
);

// Create estate
export const SHOW_ESTATE_CREATE_MODAL = type('SHOW_ESTATE_CREATE_MODAL');
export const showEstateCreateModal = action(SHOW_ESTATE_CREATE_MODAL);
export const CLOSE_ESTATE_CREATE_MODAL = type('CLOSE_ESTATE_CREATE_MODAL');
export const closeEstateCreateModal = action(CLOSE_ESTATE_CREATE_MODAL);
