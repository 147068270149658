import { createReducer, set } from '../../utils/redux';
import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from './actions';

export default createReducer(
  {
    sidebarOpen: true
  },
  {
    [OPEN_SIDEBAR]: state => set('sidebarOpen', true, state),
    [CLOSE_SIDEBAR]: state => set('sidebarOpen', false, state)
  }
);
