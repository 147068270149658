import { createSelector } from 'reselect';
import { pipe, path, prop } from 'ramda';
import namespace from './namespace';

const root = path(namespace);

export const users = pipe(
  root,
  prop('users')
);

export const getUsers = createSelector(
  [state => state.usersTmp.data],
  data => data
);

export const areUsersLoaded = createSelector(
  [state => state.usersTmp.loaded],
  bool => bool
);

export const pagination = pipe(
  root,
  prop('users'),
  prop('pagination')
);

export const pageRootless = pipe(
  root,
  prop('users'),
  prop('pagination'),
  prop('currentPage')
);

export const ui = pipe(
  root,
  prop('ui')
);
