import { createReducer } from '../../utils/redux';
import { FETCH_PAYMENT_TERMS, RECEIVE_PAYMENT_TERMS } from './actions';
import initialState from './state';

export default createReducer(initialState, {
  [FETCH_PAYMENT_TERMS]: state => {
    return {
      ...state,
      paymentTerms: { ...state.paymentTerms, fetching: true }
    };
  },
  [RECEIVE_PAYMENT_TERMS]: (state, { terms }) => {
    return {
      ...state,
      paymentTerms: {
        ...state.paymentTerms,
        fetching: false,
        loaded: true,
        data: terms
      }
    };
  }
});
