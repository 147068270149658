import React from 'react';
import PropTypes from 'prop-types';
import { has, forEach, pickBy, propOr, includes, isNil, clone } from 'ramda';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../services/history-service';

import ProtectedRoute from 'components/protected-route';

import { FullscreenLoader } from '../components/loader';

import publicRoutes from './public-routes';
import protectedRoutes from './protected-routes';

/**
 * findRouteByPath
 *
 * Tries to find by route by the provided pathname
 *
 * @param {string} path
 * @returns {boolean}
 */
const findRouteByPath = path => {
  const allRoutes = normalizeRoutes(protectedRoutes);
  return allRoutes.find(r => r.path === path);
};

export const canUserAccessPath = (path, user) => {
  const route = findRouteByPath(path);
  if (!isNil(route)) {
    return canUserAccess(user, route);
  }

  // If route cannot be found, it is safe to assume user can TRY to access it
  return true;
};
/**
 * canUserAccess
 *
 * Performs a check whether user can access provided 'route'
 *
 * @param {any} user
 * @param {any route
 * @returns {boolean}
 */
const canUserAccess = (user, route) => {
  let canAccess = true;
  if (has('requiredRoles', route)) {
    canAccess = propOr([], 'roles', user).some(role => {
      return includes(role.name, propOr([], 'requiredRoles', route));
    });
  }

  return canAccess;
};

/**
 * Filters out routes which are not available for the current organization
 *
 */
export const getRoutesForUser = (user, organization = {}, routes) => {
  const isRestrictedRoute = (organization, r) => {
    return includes(
      propOr('', 'slug', organization),
      propOr([], 'blacklist', r)
    );
  };

  const traverseRoutes = nestedRoutes => {
    if (isNil(nestedRoutes)) return;

    return nestedRoutes.reduce((acc, r) => {
      if (has('routes', r)) {
        r.routes = traverseRoutes(r.routes);
      }

      // Check access
      if (!isRestrictedRoute(organization, r) && canUserAccess(user, r)) {
        acc.push(r);
      }

      return acc;
    }, []);
  };

  return traverseRoutes(clone(routes));
};

/**
 * Normalize routes
 */
const normalizeRoutes = routes => {
  let normalized = [];

  const buildRoutes = routeArray => {
    forEach(route => {
      // if the route has sub-routes as BUT
      // has 'path' and 'component' add it as separate route also (to enable support for redirects etc)
      if (
        has('routes', route) &&
        has('component', route) &&
        has('path', route)
      ) {
        // Leave out the 'routes' (sub-array of child route) out from this
        const indexRoute = pickBy((val, key) => key !== 'routes', route);
        normalized.push(indexRoute);
      }

      if (has('routes', route)) {
        buildRoutes(route.routes);
      } else {
        normalized.push(route);
      }
    }, routeArray);
  };

  buildRoutes(routes);
  return normalized;
};

const Routes = props => {
  const {
    isAuthenticated,
    userLoaded,
    fetchUser,
    userFetching,
    activeOrganization,
    user
  } = props;

  // If fetching user, show loader
  // If user has not been loaded, try to do it automatically
  if (userFetching) {
    return <FullscreenLoader />;
  } else if (!userLoaded) {
    fetchUser();
    return <FullscreenLoader />;
  }

  return (
    <Router history={history}>
      <React.Suspense fallback={<FullscreenLoader />}>
        <Switch>
          {/* 'Public' routes, these are accessible without authentication */}}
          {normalizeRoutes(publicRoutes).map((route, i) => (
            <Route key={i} {...route} />
          ))}
          {/*
          'Protected' routes. If the user is not authenticated, we'll redirect him to the login page.
          These are also wrapped in the main layout
          */}
          {getRoutesForUser(
            user,
            activeOrganization,
            normalizeRoutes(protectedRoutes)
          ).map((route, i) => (
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              key={i}
              {...route}
            />
          ))}
        </Switch>
      </React.Suspense>
    </Router>
  );
};

Routes.propTypes = {
  isAuthenticated: PropTypes.bool,
  fetchUser: PropTypes.func,
  userLoaded: PropTypes.bool,
  user: PropTypes.shape({}),
  activeOrganization: PropTypes.shape({})
};

export default Routes;
