import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { isNil } from 'ramda';
import { expandToArray } from '../../utils/env';

const init = config => {
  let bugsnagClient = {};
  if (!isNil(process.env.REACT_APP_BUGSNAG_API_KEY)) {
    // Configure bugsnag
    bugsnagClient = bugsnag({
      apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
      filters: [
        // https://docs.bugsnag.com/platforms/javascript/react/configuration-options/#filters
        'access_token', // exact match: "access_token"
        'api_token', // exact match: "api_token"
        /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
        /^cc_/ // prefix match: "cc_number" "cc_cvv" "cc_expiry"
      ],
      notifyReleaseStages: !isNil(
        process.env.REACT_APP_BUGSNAG_NOTIFY_RELEASE_STAGES
      )
        ? expandToArray(process.env.REACT_APP_BUGSNAG_NOTIFY_RELEASE_STAGES)
        : ['production', 'staging'],
      ...config
    });

    bugsnagClient.use(bugsnagReact, React);
  }
  return bugsnagClient;
};
export default init;
