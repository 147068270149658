export const commonEntity = {
  initialState: {
    fetching: false,
    loaded: false,
    error: null,
    data: [],
    pagination: {
      count: 1,
      currentPage: 1,
      total: 1,
      totalPages: 1
    }
  },
  reducers: {
    fetchStart(state, action) {
      state.fetching = true;
      state.loaded = false;
      state.error = null;
    },
    fetchSuccess(state, action) {
      state.fetching = false;
      state.error = null;
      state.loaded = true;
      state.data = action.payload;
    },
    fetchError(state, action) {
      state.fetching = false;
      state.loaded = true;
      state.error = action.payload;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    }
  }
};

export const editableEntity = {
  initialState: {
    ...commonEntity.initialState,
    form: {
      submitting: false,
      error: null
    }
  },
  reducers: {
    ...commonEntity.reducers,
    editStart(state, action) {
      state.form.submitting = true;
      state.form.error = null;
    },
    editSuccess(state, action) {
      state.form.submitting = false;
      state.form.loaded = true;
    },
    editError(state, action) {
      state.form.submitting = false;
      state.form.error = action.payload;
    }
  }
};
