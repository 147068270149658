import { createReducer } from '../../utils/redux';
import { FETCH_ROLES, RECEIVE_ROLES, FETCH_ROLES_FAILED } from './actions';
import initialState from './state';

export default createReducer(initialState, {
  [FETCH_ROLES]: state => {
    return {
      ...state,
      roles: {
        ...state.roles,
        fetching: true
      }
    };
  },
  [RECEIVE_ROLES]: (state, { roles }) => {
    return {
      ...state,
      roles: {
        ...state.roles,
        data: roles,
        loaded: true,
        fetching: false
      }
    };
  },
  [FETCH_ROLES_FAILED]: (state, { error }) => {
    return {
      ...state,
      roles: {
        ...state.roles.data,
        fetching: false,
        error: true
      }
    };
  }
});
