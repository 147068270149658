import { createReducer } from '../../utils/redux';
import {
  FETCH_CONTRACTS_BY_STATE,
  FETCH_CONTRACTS_BY_STATE_ERROR,
  RECEIVE_CONTRACTS_BY_STATE,
  SET_CONTRACTS_BY_STATE_QUERY,
  SET_CONTRACTS_BY_STATE_PAGINATION,
  FETCH_CONTRACT_TYPES,
  FETCH_CONTRACT_TYPES_ERROR,
  RECEIVE_CONTRACT_TYPES,
  FETCH_CONTRACTS_BY_ESTATE,
  FETCH_CONTRACTS_BY_ESTATE_ERROR,
  RECEIVE_CONTRACTS_BY_ESTATE
} from './actions';
import initialState from './state';
import { removeEmptyValues } from '../../utils/request';

export default createReducer(initialState, {
  [FETCH_CONTRACTS_BY_STATE]: (state, { state: contractState }) => {
    return {
      ...state,
      byState: {
        ...state.byState,
        [contractState]: {
          ...state.byState[contractState],
          fetching: true,
          error: false
        }
      }
    };
  },
  [FETCH_CONTRACTS_BY_STATE_ERROR]: (
    state,
    { state: contractState, error }
  ) => {
    return {
      ...state,
      byState: {
        ...state.byState,
        [contractState]: {
          ...state.byState[contractState],
          fetching: false,
          error: true,
          loaded: true
        }
      }
    };
  },
  [RECEIVE_CONTRACTS_BY_STATE]: (
    state,
    { state: contractState, contracts, pagination }
  ) => {
    return {
      ...state,
      byState: {
        ...state.byState,
        [contractState]: {
          ...state.byState[contractState],
          fetching: false,
          loaded: true,
          data: contracts,
          pagination
        }
      }
    };
  },
  [SET_CONTRACTS_BY_STATE_QUERY]: (state, { state: contractState, query }) => {
    return {
      ...state,
      byState: {
        ...state.byState,
        [contractState]: {
          ...state.byState[contractState],
          query: removeEmptyValues(query)
        }
      }
    };
  },
  [SET_CONTRACTS_BY_STATE_PAGINATION]: (
    state,
    { state: contractState, page }
  ) => {
    return {
      ...state,
      byState: {
        ...state.byState,
        [contractState]: {
          ...state.byState[contractState],
          pagination: {
            ...state.byState[contractState].pagination,
            currentPage: page
          }
        }
      }
    };
  },
  [FETCH_CONTRACT_TYPES]: state => {
    return {
      ...state,
      contractTypes: {
        ...state.contractTypes,
        fetching: true,
        error: false
      }
    };
  },
  [FETCH_CONTRACT_TYPES_ERROR]: state => {
    return {
      ...state,
      contractTypes: {
        ...state.contractTypes,
        fetching: false,
        loaded: true,
        error: true
      }
    };
  },
  [RECEIVE_CONTRACT_TYPES]: (state, { contractTypes }) => {
    return {
      ...state,
      contractTypes: {
        ...state.contractTypes,
        fetching: false,
        loaded: true,
        data: contractTypes
      }
    };
  },
  [FETCH_CONTRACTS_BY_ESTATE]: (state, { id }) => {
    return {
      ...state,
      contractsByEstate: {
        ...state.contractsByEstate,
        estateId: id,
        fetching: true,
        error: false
      }
    };
  },
  [FETCH_CONTRACTS_BY_ESTATE_ERROR]: (state, { id, error }) => {
    return {
      ...state,
      contractsByEstate: {
        ...state.contractsByEstate,
        estateId: id,
        fetching: false,
        loaded: true,
        error: true
      }
    };
  },
  [RECEIVE_CONTRACTS_BY_ESTATE]: (state, { id, contracts }) => {
    return {
      ...state,
      contractsByEstate: {
        ...state.contractsByEstate,
        estateId: id,
        data: contracts,
        loaded: true,
        fetching: false,
        error: false
      }
    };
  }
});
