import { takeEvery, fork, put, call } from 'redux-saga/effects';
import { FETCH_PAYMENT_TERMS, receivePaymentTerms } from './actions';
import apiClient from '../../services/api-service';

function* handleFetchPaymentTerms() {
  try {
    const {
      data: { data: terms }
    } = yield call(apiClient.get, '/V1/payment-terms');
    yield put(receivePaymentTerms(terms));
  } catch {}
}

function* handleWatchFetchPaymentTerms() {
  yield takeEvery(FETCH_PAYMENT_TERMS, handleFetchPaymentTerms);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchFetchPaymentTerms);
}
