import { curry, path, assocPath } from 'ramda';

export const createType = curry(
  (namespace, type) => `${namespace.join('/')}/${type}`
);

export function createActionCreator(type, ...argNames) {
  return function(...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

// @TODO: kysy näiden refaktoroinnista Rikulta
export const get = curry((pathname, stateObj) =>
  path(pathname.split('.'), stateObj)
);

export const set = curry((pathname, value, stateObj) =>
  assocPath(pathname.split('.'), value, stateObj)
);
