import { takeEvery, fork, call, put, select } from 'redux-saga/effects';
import {
  FETCH_USERS,
  FETCH_USERS_ERROR,
  RECEIVE_USERS,
  CLOSE_USER_CREATE_MODAL,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  EDIT_USER,
  // EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER,
  // DELETE_USER_SUCCESS,
  DELETE_USER_ERROR
} from './actions';
import { pagination } from './selectors';
import apiClient from '../../services/api-service';
import { normalize } from '../../utils/response';
import { actions as NotificationActions } from 'modules/notifications';

function* handleFetchUsers({ page }) {
  try {
    const {
      data: {
        data: users,
        meta: { pagination }
      }
    } = yield call(apiClient.get, '/V1/users', {
      params: {
        page,
        include: 'roles'
      }
    });
    yield put({
      type: RECEIVE_USERS,
      users: normalize(users),
      pagination
    });
  } catch {
    yield put({ type: FETCH_USERS_ERROR });
  }
}

function* handleWatchFetchUsers() {
  yield takeEvery(FETCH_USERS, handleFetchUsers);
}
function* handleReceiveUsers(action) {}

function* handleWatchReceiveUsers() {
  yield takeEvery(RECEIVE_USERS, handleReceiveUsers);
}
function* handleCreateUser({ user }) {
  try {
    yield call(apiClient.post, '/V1/users/', user);
    const { currentPage: p } = yield select(pagination);
    yield put({ type: CREATE_USER_SUCCESS });
    yield put({ type: CLOSE_USER_CREATE_MODAL });
    yield put({ type: FETCH_USERS, page: p });
    yield put(
      NotificationActions.add({
        variant: 'success',
        content: 'Käyttäjä kutsuttu!'
      })
    );
  } catch {
    yield put({ type: CREATE_USER_ERROR });
  }
}

function* handleWatchCreateUser() {
  yield takeEvery(CREATE_USER, handleCreateUser);
}
function* handleEditUser({ user }) {
  try {
    yield call(apiClient.patch, `/V1/users/${user.id}`, user);
    const { currentPage: p } = yield select(pagination);
    yield put({ type: FETCH_USERS, page: p });

    yield put(
      NotificationActions.add({
        variant: 'success',
        content: 'Käyttäjä tallennettu!'
      })
    );
  } catch {
    yield put({ type: EDIT_USER_ERROR });
    yield put(
      NotificationActions.add({
        variant: 'danger',
        content: 'Käyttäjän tallennus epäonnistui!'
      })
    );
  }
}

function* handleWatchEditUser() {
  yield takeEvery(EDIT_USER, handleEditUser);
}
function* handleDeleteUser({ user: { id } }) {
  try {
    yield call(apiClient.delete, `/V1/users/${id}`);
    const { currentPage: p } = yield select(pagination);
    yield put({ type: FETCH_USERS, page: p });
  } catch {
    yield put({ type: DELETE_USER_ERROR });
  }
}

function* handleWatchDeleteUser() {
  yield takeEvery(DELETE_USER, handleDeleteUser);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchFetchUsers);
  yield fork(handleWatchReceiveUsers);
  yield fork(handleWatchCreateUser);
  yield fork(handleWatchEditUser);
  yield fork(handleWatchDeleteUser);
}
