import { createSlice } from 'redux-starter-kit';
import { commonEntity } from 'utils/store';
import { combineReducers } from 'redux';

// Task slice
export const taskSlice = createSlice({
  slice: 'task',
  initialState: {
    ...commonEntity.initialState,
    form: {
      submitting: false,
      loaded: false,
      error: null
    }
  },
  reducers: {
    ...commonEntity.reducers,
    editStart(state, action) {
      state.form.submitting = true;
      state.form.error = null;
    },
    editSuccess(state, action) {
      state.form.submitting = false;
      state.form.loaded = true;
    },
    editError(state, action) {
      state.form.submitting = false;
      state.form.error = action.payload;
    }
  }
});

// Users slice
export const usersSlice = createSlice({
  slice: 'task.users',
  initialState: {
    ...commonEntity.initialState
  },
  reducers: {
    ...commonEntity.reducers
  }
});

export default combineReducers({
  task: taskSlice.reducer,
  users: usersSlice.reducer
});
