export default {
  gray: '#F8F8F8',
  grayDark: '#ebebeb',
  grayDarker: '#b7b7b7',
  grayDarkest: '#161616',
  slate: '#4d4d4d',
  white: '#ffffff',
  green: '#28b759',
  orange: '#db7e34',
  yellow: '#d2b32d',
  red: '#fc3d48',
  black: '#1f1f1f',
  blue: '#7082c8',
  blueViolet: '#7b36e7',
  babyBlue: '#30afff',
  babyBlueDark: '#10a0fb',
  babyMint: '#e5f7ec'
};
