import { __, pick, pickBy, keys, reject, pipe, union, type, all } from 'ramda';
import qs from 'query-string';

/**
 * Removes empty values from object
 *
 * @export
 * @param {*} object
 * @param {*} [ignore=[]]
 * @returns
 */
export function removeEmptyValues(object, ignore = []) {
  return pipe(
    keys,
    reject(key => type(object[key]) === 'String' && object[key].length === 0),
    union(ignore),
    pick(__, object)
  )(object);
}

/**
 * Builds new query from provided values
 *
 * @export
 * @param {*} q The new query object
 * @param {*} query Old query
 * @param {*} supportedQueryParams supported query params
 * @returns
 */
export function buildQuery(q, query, supportedQueryParams) {
  const newQuery = pipe(
    // only allow supported params
    pick(supportedQueryParams),
    // remove null values
    pickBy(val => val !== null)
  )({ ...query, ...q });

  return newQuery;
}

/**
 * Build query string from provided values.
 * For supported input params, see 'buildQuery()'
 * @param {*} props (q, query, supportedParams)
 */
export function buildQueryString(...props) {
  return qs.stringify(buildQuery(...props));
}

/**
 * Check whether query is valid according to the rules
 * isQueryStringValid
 *
 * @param {string} queryString=''
 * @param {array} rules=[]
 * @returns {boolean}
 */
export function isQueryStringValid(queryString = '', rules = []) {
  const q = qs.parse(queryString);
  const checks = rules.map(r => {
    return r(q);
  });
  return all(c => !!c, checks);
}

export function getWildcardQuery(query = '') {
  // Replace spaces with '%' for wildcard expansion (meiko/laravel-filterable)
  return query.trim().replace(' ', '%');
}
