import React from 'react';
import { Redirect } from 'react-router-dom';
import { canUserAccessPath } from 'routes';
import { useAuth } from 'hooks/auth';
/**
 * FallbackRedirect
 *
 * Try to do a redirection. Will use 'fallback' route if
 * user cannot access the route.
 *
 * @param props
 * @returns {undefined}
 */
const FallbackRedirect = props => {
  const { to, fallback = '/' } = props;

  const { user } = useAuth();

  const redirect = canUserAccessPath(to, user) ? to : fallback;
  return <Redirect to={redirect} />;
};

export default FallbackRedirect;
