import { createActionCreator as action, createType } from '../../utils/redux';
import namespace from './namespace';

const type = createType(namespace);
export const FETCH_CLIENTS = type('FETCH_CLIENTS');
export const fetchClients = action(FETCH_CLIENTS, 'page');
export const CREATE_CLIENT = type('CREATE_CLIENT');
export const createClient = action(CREATE_CLIENT, 'client');
export const EDIT_CLIENT = type('EDIT_CLIENT');
export const editClient = action(EDIT_CLIENT, 'client');
export const DELETE_CLIENT = type('DELETE_CLIENT');
export const deleteClient = action(DELETE_CLIENT, 'client');

export const RECEIVE_CLIENTS = type('RECEIVE_CLIENTS');
export const receiveClients = action(RECEIVE_CLIENTS, 'clients');
export const RECEIVE_PAGE = type('RECEIVE_PAGE');
export const receivePage = action(RECEIVE_PAGE, 'data', 'pagination');

export const CLIENT_DELETED = type('CLIENT_DELETED');
export const clientDeleted = action(CLIENT_DELETED, 'client');

export const CLIENT_CREATED = type('CLIENT_CREATED');
export const clientCreated = action(CLIENT_CREATED, 'client');

export const CLIENT_MODIFIED = type('CLIENT_MODIFIED');
export const clientModified = action(CLIENT_MODIFIED, 'client');

export const FETCH_FAILED = type('FETCH_FAILED');

export const FETCH_CLIENT_TYPES = type('FETCH_CLIENT_TYPES');
export const fetchClientTypes = action(FETCH_CLIENT_TYPES);

export const RECEIVE_CLIENT_TYPES = type('RECEIVE_CLIENT_TYPES');

export const SHOW_CLIENT_CREATE_MODAL = type('SHOW_CLIENT_CREATE_MODAL');
export const showClientCreateModal = action(SHOW_CLIENT_CREATE_MODAL);
export const CLOSE_CLIENT_CREATE_MODAL = type('CLOSE_CLIENT_CREATE_MODAL');
export const closeClientCreateModal = action(CLOSE_CLIENT_CREATE_MODAL);
export const CREATE_CLIENT_SUCCESS = type('CREATE_CLIENT_SUCCESS');
export const createClientSuccess = action(CREATE_CLIENT_SUCCESS, 'product');

export const CREATE_CLIENT_ERROR = type('CREATE_CLIENT_ERROR');
export const createClientError = action(CREATE_CLIENT_ERROR, 'error');
