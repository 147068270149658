import { spawn } from 'redux-saga/effects';
/* PLOP_APPEND_SAGA_IMPORT */
import RolesSaga from './roles/saga';

import PaymentTermsSaga from './payment-terms/saga';

import ContractServicesSaga from './contract-services/saga';
import ContractsSaga from './contracts/saga';

import UsersSaga from './users/saga';

import EstatesSaga from './estates/saga';
import ClientsSaga from './clients/saga';
import ProductsSaga from './products/saga';

import NavigationSaga from './navigation/saga';
import UserSaga from './user/saga';

export default function* rootSaga() {
  /* PLOP_APPEND_SAGA */
  yield spawn(RolesSaga);

  yield spawn(PaymentTermsSaga);

  yield spawn(ContractServicesSaga);

  yield spawn(ContractsSaga);

  yield spawn(UsersSaga);

  yield spawn(EstatesSaga);
  yield spawn(ClientsSaga);
  yield spawn(ProductsSaga);

  yield spawn(NavigationSaga);
  yield spawn(UserSaga);
}
