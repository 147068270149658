import { has, keys, is, map, reduce, pipe } from 'ramda';

function unwrap(data) {
  if (is(Array, data)) {
    return map(unwrap, data);
  }
  if (is(Object, data)) {
    return reduce(
      (acc, key) => {
        const value = data[key];
        return {
          ...acc,
          [key]:
            is(Object, value) && has('data', value)
              ? unwrap(value.data)
              : is(Object, value)
              ? unwrap(value)
              : value
        };
      },
      {},
      keys(data)
    );
  }
  return data;
}

export const normalize = pipe(unwrap);
