import { createSelector } from 'reselect';
import { pipe, path, prop } from 'ramda';
import namespace from './namespace';

const root = path(namespace);

export const getContracts = createSelector(
  [state => state.contractsTmp.data],
  data => data
);

export const areContractsLoaded = createSelector(
  [state => state.contractsTmp.loaded],
  bool => bool
);

export const areContractsFetching = createSelector(
  [state => state.contractsTmp.fetching],
  bool => bool
);

export const getContractsPagination = createSelector(
  [state => state.contractsTmp.pagination],
  data => data
);

export const contractsByState = pipe(
  root,
  prop('byState')
);

export const makeGetContractsByStateSelector = state => {
  return createSelector(
    [contractsByState],
    contractsByState => contractsByState[state]
  );
};

export const contractTypes = pipe(
  root,
  prop('contractTypes')
);

export const contractsByEstate = pipe(
  root,
  prop('contractsByEstate')
);
