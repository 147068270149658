import { differenceInMinutes, isBefore, parseISO, isValid } from 'date-fns';
import { prop, isEmpty, has } from 'ramda';
/**
 * Helper function for determing whether token should be refreshed
 *
 * Token should be refreshed when:
 * 1. Token is missing expires_in
 * 2. Token expiresIn date is not valid
 * 3. The token will expire in less than ten minutes
 *
 * @param {object} token  Example token: { expires_in: '2019-03-03T13:05:45976Z }
 * @returns {boolean}
 */
export const shouldRefreshToken = token => {
  const isoDate = prop('expires_in', token);

  const expiresIn = parseISO(isoDate);

  const now = new Date();
  return (
    isEmpty(token) ||
    !has('expires_in', token) ||
    !isValid(expiresIn) ||
    (isBefore(now, expiresIn) && differenceInMinutes(expiresIn, now) < 10)
  );
};
