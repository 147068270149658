import { createSlice } from 'redux-starter-kit';

// Incremental id
let nextId = 0;

// Task slice
const Notifications = createSlice({
  slice: 'notifications',
  initialState: {
    all: {}
  },
  reducers: {
    add(state, action) {
      const id = action.payload.id || String(++nextId);
      state.all[id] = { id, ...action.payload };
    },
    remove(state, action) {
      delete state.all[action.payload];
    }
  }
});

export const flash = (n, ms = 3000) => dispatch => {
  const id = n.id || ++nextId;

  dispatch(actions.add({ id, ...n }));

  setTimeout(() => {
    dispatch(actions.remove(id));
  }, ms);
};

export const effects = { flash };
export const { actions, slice } = Notifications;
export default Notifications.reducer;
