import { defaultTo } from 'ramda';
import { createReducer } from '../../utils/redux';
import {
  FETCH_PRODUCTS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,
  SHOW_PRODUCT_CREATE_MODAL,
  CLOSE_PRODUCT_CREATE_MODAL,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  RECEIVE_PRODUCTS,
  FETCH_PRODUCT_TYPES,
  RECEIVE_PRODUCT_TYPES,
  RECEIVE_PRODUCTS_OF_TYPE,
  FETCH_PRODUCTS_OF_TYPE
} from './actions';
import { pageRootless as selectPage } from './selectors';
import initialState from './state';

export default createReducer(initialState, {
  [FETCH_PRODUCTS]: (state, { page }) => {
    const currentPage = defaultTo(selectPage(state), page);
    return {
      ...state,
      fetching: true,
      pagination: {
        ...state.pagination,
        currentPage
      }
    };
  },
  [FETCH_PRODUCT_TYPES]: state => {
    return {
      ...state,
      productTypes: { ...state.productTypes, fetching: true }
    };
  },
  [RECEIVE_PRODUCT_TYPES]: (state, { productTypes }) => {
    return {
      ...state,
      fetching: false,
      productTypes: { data: productTypes, fetching: false, loaded: true }
    };
  },
  [CREATE_PRODUCT]: state => {
    return { ...state, fetching: true };
  },
  [RECEIVE_PRODUCTS]: (state, { products, pagination }) => {
    return { ...state, products, pagination, fetching: false, loaded: true };
  },
  [SHOW_PRODUCT_CREATE_MODAL]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        productCreateModal: { ...state.ui.productCreateModal, show: true }
      }
    };
  },
  [CLOSE_PRODUCT_CREATE_MODAL]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        productCreateModal: { ...state.ui.productCreateModal, show: false }
      }
    };
  },
  [CREATE_PRODUCT]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        productCreateModal: {
          ...state.ui.productCreateModal,
          fetching: true,
          error: false
        }
      }
    };
  },
  [CREATE_PRODUCT_SUCCESS]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        productCreateModal: {
          ...state.ui.productCreateModal,
          fetching: false,
          error: false
        }
      }
    };
  },
  [CREATE_PRODUCT_ERROR]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        productCreateModal: {
          ...state.ui.productCreateModal,
          fetching: false,
          error: true
        }
      }
    };
  },
  [EDIT_PRODUCT]: state => {
    return { ...state, fetching: true };
  },
  [DELETE_PRODUCT]: state => {
    return { ...state, fetching: true };
  },

  [FETCH_PRODUCTS_OF_TYPE]: (state, { productType: { name } }) => {
    return {
      ...state,
      productsByType: {
        ...state.productsByType,
        [name]: { ...state.productsByType[name], fetching: true }
      }
    };
  },

  [RECEIVE_PRODUCTS_OF_TYPE]: (state, { productType, products }) => {
    return {
      ...state,
      productsByType: {
        ...state.productsByType,
        [productType]: {
          ...state.productsByType[productType],
          loaded: true,
          fetching: false,
          data: products
        }
      }
    };
  }
});
