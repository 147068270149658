import { takeEvery, fork } from 'redux-saga/effects';
import {
  NAVIGATE,
  REPLACE,
  RELOAD,
  OPEN_TAB,
  GO_BACK,
  GO_FORWARD
} from './actions';
import history from '../../services/history-service';

// NAVIGATE
// (CRA eslint requires to use yield inside generator functions)
// eslint-disable-next-line
function* handleNavigate({ path, state }) {
  history.push(path, state);
}
function* handleWatchNavigate() {
  yield takeEvery(NAVIGATE, handleNavigate);
}

/// REPLACE
// eslint-disable-next-line
function* handleReplace({ path, state }) {
  history.replace(path, state);
}
function* handleWatchReplace() {
  yield takeEvery(REPLACE, handleReplace);
}

// RELOAD
// eslint-disable-next-line
function* handleReload() {
  // reload the browser window
  window.location.reload();
}
function* handleWatchReload() {
  yield takeEvery(RELOAD, handleReload);
}

// OPEN TAB
// eslint-disable-next-line
function* handleOpenTab({ path, newWindow }) {
  // Window is opened by direct user action before entering saga to avoid getting hit by popup blocker
  //
  // Usage example:
  // const blankWindow = window.open('', '_blank');
  // dispatch(openTab('https://www.google.com', blankWindow));

  newWindow.location = path;
}
function* handleWatchOpenTab() {
  yield takeEvery(OPEN_TAB, handleOpenTab);
}

// GO BACK
// eslint-disable-next-line
function* handleGoBack() {
  history.goBack();
}
function* handleWatchGoBack() {
  yield takeEvery(GO_BACK, handleGoBack);
}

// GO FORWARD
// eslint-disable-next-line
function* handleGoForward() {
  history.goForward();
}
function* handleWatchGoForward() {
  yield takeEvery(GO_FORWARD, handleGoForward);
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchNavigate);
  yield fork(handleWatchReplace);
  yield fork(handleWatchReload);
  yield fork(handleWatchOpenTab);
  yield fork(handleWatchGoBack);
  yield fork(handleWatchGoForward);
}
