import { createReducer } from '../../utils/redux';
import {
  FETCH_ESTATES,
  FETCH_ESTATES_FAILED,
  RECEIVE_ESTATES,
  SET_ESTATES_QUERY,
  SET_ESTATES_PAGINATION,
  FETCH_ESTATE,
  FETCH_ESTATE_FAILED,
  RECEIVE_ESTATE,
  SAVE_ESTATE_LAYOUT,
  SAVE_ESTATE_LAYOUT_FAILED,
  FETCH_LEADS,
  RECEIVE_LEADS,
  FETCH_LEADS_FAILED,
  FETCH_ESTATE_ATTACHMENTS,
  FETCH_ESTATE_ATTACHMENTS_FAILED,
  DELETE_ESTATE_ATTACHMENT_FROM_STORE,
  RECEIVE_ESTATE_ATTACHMENTS,
  CLEAR_ESTATE_ATTACHMENTS,
  FETCH_EQUIPMENT_SURVEY_TRANSCRIPT,
  FETCH_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED,
  RECEIVE_EQUIPMENT_SURVEY_TRANSCRIPT,
  EDIT_EQUIPMENT_SURVEY_TRANSCRIPT,
  EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_SUCCESS,
  EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED,
  SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL,
  SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_SUCCESS,
  SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_FAILED,
  CLEAR_EQUIPMENT_SURVEY_TRANSCRIPT,
  SET_LEADS_QUERY,
  SET_LEADS_PAGINATION,
  SHOW_ESTATE_CREATE_MODAL,
  CLOSE_ESTATE_CREATE_MODAL
} from './actions';
import { filter } from 'ramda';
import initialState from './state';
import { removeEmptyValues } from '../../utils/request';

export default createReducer(initialState, {
  [FETCH_ESTATES]: state => {
    return {
      ...state,
      estates: {
        ...state.estates,
        fetching: true,
        error: false
      }
    };
  },
  [RECEIVE_ESTATES]: (state, { data, pagination }) => {
    return {
      ...state,
      estates: {
        ...state.estates,
        data,
        pagination,
        fetching: false,
        loaded: true
      }
    };
  },
  [FETCH_ESTATES_FAILED]: (state, { error }) => ({
    ...state,
    estates: { ...state.estates, fetching: false, loaded: false, error }
  }),
  [SET_ESTATES_QUERY]: (state, { query, page }) => ({
    ...state,
    estates: {
      ...state.estates,
      query: removeEmptyValues(query)
    }
  }),
  [SET_ESTATES_PAGINATION]: (state, { page }) => ({
    ...state,
    estates: {
      ...state.estates,
      pagination: {
        ...state.estates.pagination,
        currentPage: page
      }
    }
  }),
  // Single estate
  [FETCH_ESTATE]: state => {
    return { ...state, estate: { ...state.estate, fetching: true } };
  },
  [FETCH_ESTATE_FAILED]: state => {
    // TODO
    return state;
  },
  [RECEIVE_ESTATE]: (state, { estate }) => {
    return {
      ...state,
      estate: {
        ...state.estate,
        data: estate,
        fetching: false,
        loaded: true
      }
    };
  },
  [SAVE_ESTATE_LAYOUT]: (state, { data }) => {
    return { ...state, estate: { ...state.estate, fetching: false } };
  },
  [SAVE_ESTATE_LAYOUT_FAILED]: state => {
    // TODO
    return state;
  },
  // Attachments
  [FETCH_ESTATE_ATTACHMENTS]: state => {
    return {
      ...state,
      estate: {
        ...state.estate,
        attachments: {
          ...state.estate.attachments,
          fetching: true
        }
      }
    };
  },
  [RECEIVE_ESTATE_ATTACHMENTS]: (state, { attachments }) => {
    return {
      ...state,
      estate: {
        ...state.estate,
        attachments: {
          loaded: true,
          fetching: false,
          data: attachments
        }
      }
    };
  },
  [CLEAR_ESTATE_ATTACHMENTS]: state => {
    return {
      ...state,
      estate: {
        ...state.estate,
        attachments: initialState.estate.attachments
      }
    };
  },
  [FETCH_ESTATE_ATTACHMENTS_FAILED]: (state, { attachments }) => {
    return {
      ...state,
      estate: {
        ...state.estate,
        attachments: {
          ...state.estate.attachments,
          fetching: false,
          error: true
        }
      }
    };
  },
  [DELETE_ESTATE_ATTACHMENT_FROM_STORE]: (state, { id }) => {
    return {
      ...state,
      estate: {
        ...state.estate,
        attachments: {
          ...state.estate.attachments,
          data: filter(a => a.id !== id, state.estate.attachments.data)
        }
      }
    };
  },
  [FETCH_EQUIPMENT_SURVEY_TRANSCRIPT]: state => {
    return {
      ...state,
      estate: {
        ...state.estate,
        equipmentSurveyTranscript: {
          ...state.estate.equipmentSurveyTranscript,
          fetching: true
        }
      }
    };
  },
  [RECEIVE_EQUIPMENT_SURVEY_TRANSCRIPT]: (
    state,
    { equipmentSurveyTranscript }
  ) => {
    return {
      ...state,
      estate: {
        ...state.estate,
        equipmentSurveyTranscript: {
          loaded: true,
          fetching: false,
          data: equipmentSurveyTranscript
        }
      }
    };
  },
  [FETCH_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED]: state => {
    return {
      ...state,
      estate: {
        ...state.estate,
        equipmentSurveyTranscript: {
          ...state.estate.equipmentSurveyTranscript,
          error: true,
          fetching: false,
          loaded: true
        }
      }
    };
  },
  [EDIT_EQUIPMENT_SURVEY_TRANSCRIPT]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        editEquipmentSurveyTranscriptForm: {
          ...state.ui.editEquipmentSurveyTranscriptForm,
          fetching: true
        }
      }
    };
  },
  [EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_SUCCESS]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        editEquipmentSurveyTranscriptForm: {
          ...state.ui.editEquipmentSurveyTranscriptForm,
          fetching: false,
          error: false
        }
      }
    };
  },
  [EDIT_EQUIPMENT_SURVEY_TRANSCRIPT_FAILED]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        editEquipmentSurveyTranscriptForm: {
          ...state.ui.editEquipmentSurveyTranscriptForm,
          fetching: false,
          error: true
        }
      }
    };
  },
  [SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        sendEquipmentSurveyTranscriptToEmailForm: {
          ...state.ui.sendEquipmentSurveyTranscriptToEmailForm,
          fetching: true,
          error: false
        }
      }
    };
  },
  [SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_SUCCESS]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        sendEquipmentSurveyTranscriptToEmailForm: {
          ...state.ui.sendEquipmentSurveyTranscriptToEmailForm,
          fetching: false,
          error: false,
          success: true
        }
      }
    };
  },
  [SEND_EQUIPMENT_SURVEY_TRANSCRIPT_TO_EMAIL_FAILED]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        sendEquipmentSurveyTranscriptToEmailForm: {
          ...state.ui.sendEquipmentSurveyTranscriptToEmailForm,
          fetching: false,
          error: true
        }
      }
    };
  },
  [CLEAR_EQUIPMENT_SURVEY_TRANSCRIPT]: state => ({
    ...state,
    estate: {
      ...state.estate,
      equipmentSurveyTranscript: initialState.estate.equipmentSurveyTranscript
    },
    ui: {
      // We also reset the state for this 'form'
      ...state.ui,
      sendEquipmentSurveyTranscriptToEmailForm:
        initialState.ui.sendEquipmentSurveyTranscriptToEmailForm
    }
  }),
  [FETCH_LEADS]: state => {
    return {
      ...state,
      leads: { ...state.leads, fetching: true, error: false }
    };
  },
  [RECEIVE_LEADS]: (state, { data, pagination }) => {
    return {
      ...state,
      leads: { ...state.leads, data, pagination, fetching: false, loaded: true }
    };
  },
  [FETCH_LEADS_FAILED]: (state, { error }) => ({
    ...state,
    leads: { ...state.leads, fetching: false, loaded: false, error }
  }),
  [SET_LEADS_QUERY]: (state, { query, page }) => ({
    ...state,
    leads: {
      ...state.leads,
      query: removeEmptyValues(query)
    }
  }),
  [SET_LEADS_PAGINATION]: (state, { page }) => ({
    ...state,
    leads: {
      ...state.leads,
      pagination: {
        ...state.leads.pagination,
        currentPage: page
      }
    }
  }),
  [SHOW_ESTATE_CREATE_MODAL]: state => ({
    ...state,
    ui: {
      ...state.ui,
      createEstateModal: {
        ...state.ui.createEstateModal,
        show: true
      }
    }
  }),
  [CLOSE_ESTATE_CREATE_MODAL]: state => ({
    ...state,
    ui: {
      ...state.ui,
      createEstateModal: {
        ...state.ui.createEstateModal,
        show: false
      }
    }
  })
});
