import { pipe, path, prop } from 'ramda';
import { createSelector } from 'redux-starter-kit';
import namespace from './namespace';

const root = path(namespace);

export const products = pipe(
  root,
  prop('products')
);

export const pagination = pipe(
  root,
  prop('pagination')
);

export const page = pipe(
  root,
  prop('pagination'),
  prop('currentPage')
);

export const pageRootless = pipe(
  prop('pagination'),
  prop('currentPage')
);

export const isFetching = pipe(
  root,
  prop('fetching')
);

export const isLoaded = pipe(
  root,
  prop('loaded')
);

export const productTypes = pipe(
  root,
  prop('productTypes')
);

export const ui = pipe(
  root,
  prop('ui')
);

export const productsByType = pipe(
  root,
  prop('productsByType')
);

// New pattern
export const getProducts = createSelector(
  [state => state.productsTmp.data],
  products => products
);

export const areProductsLoaded = createSelector(
  [state => state.productsTmp.loaded],
  bool => bool
);

export const areProductsFetching = createSelector(
  [state => state.productsTmp.fetching],
  bool => bool
);

export const getProductsByTypeName = createSelector(
  [getProducts],
  (products = []) => {
    const values = {};
    products.forEach(p => {
      const { name } = p.productType;
      values[name] = [...(values[name] || []), p];
    });

    return values;
  }
);
