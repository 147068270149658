import { createActionCreator as action, createType } from '../../utils/redux';
import namespace from './namespace';

const type = createType(namespace);
export const NAVIGATE = type('NAVIGATE');
export const navigate = action(NAVIGATE, 'path', 'query');
export const RELOAD = type('RELOAD');
export const reload = action(RELOAD);
export const OPEN_TAB = type('OPEN_TAB');
export const openTab = action(OPEN_TAB, 'path', 'newWindow');
export const GO_BACK = type('GO_BACK');
export const goBack = action(GO_BACK);
export const GO_FORWARD = type('GO_FORWARD');
export const goForward = action(GO_FORWARD);
export const REPLACE = type('REPLACE');
export const replace = action(REPLACE, 'path', 'query');
