import { lazy } from 'react';

const Login = lazy(() => import('containers/login-container'));
const Register = lazy(() => import('pages/register'));
const SetPassword = lazy(() => import('pages/set-password'));
const ForgotPassword = lazy(() => import('pages/forgot-password'));
const InstallerTask = lazy(() => import('pages/installer-task'));
const NotFound = lazy(() => import('pages/not-found'));

// Routes accessible by anyone
export default [
  {
    path: '/kirjaudu',
    exact: true,
    component: Login
  },
  {
    path: '/rekisteroidy',
    exact: true,
    component: Register
  },
  {
    path: '/aseta-salasana/:uuid',
    component: SetPassword
  },
  {
    path: '/unohdin-salasanani',
    component: ForgotPassword
  },
  {
    path: '/asennukset/:id',
    component: InstallerTask
  },
  {
    path: '/hups',
    component: NotFound
  }
];
