import { createActionCreator as action, createType } from '../../utils/redux';
import namespace from './namespace';

const type = createType(namespace);
export const FETCH_CONTRACTS_BY_STATE = type('FETCH_CONTRACTS_BY_STATE');
export const fetchContractsByState = action(FETCH_CONTRACTS_BY_STATE, 'state');
export const FETCH_CONTRACTS_BY_STATE_ERROR = type(
  'FETCH_CONTRACTS_BY_STATE_ERROR'
);
export const fetchContractsByStateError = action(
  FETCH_CONTRACTS_BY_STATE_ERROR,
  'state',
  'error'
);
export const RECEIVE_CONTRACTS_BY_STATE = type('RECEIVE_CONTRACTS_BY_STATE');
export const receiveContractsByState = action(
  RECEIVE_CONTRACTS_BY_STATE,
  'state',
  'contracts',
  'pagination'
);
export const FETCH_CONTRACTS_BY_STATE_SUCCESS = type(
  'FETCH_CONTRACTS_BY_STATE_SUCCESS'
);
export const fetchContractsByStateSuccess = action(
  FETCH_CONTRACTS_BY_STATE_SUCCESS,
  'state'
);
export const SET_CONTRACTS_BY_STATE_QUERY = type(
  'SET_CONTRACTS_BY_STATE_QUERY'
);
export const setContractsByStateQuery = action(
  SET_CONTRACTS_BY_STATE_QUERY,
  'state',
  'query'
);
export const SET_CONTRACTS_BY_STATE_PAGINATION = type(
  'SET_CONTRACTS_BY_STATE_PAGINATION'
);
export const setContractsByStatePagination = action(
  SET_CONTRACTS_BY_STATE_PAGINATION,
  'state',
  'page'
);

export const FETCH_CONTRACT_TYPES = type('FETCH_CONTRACT_TYPES');
export const fetchContractTypes = action(FETCH_CONTRACT_TYPES);
export const RECEIVE_CONTRACT_TYPES = type('RECEIVE_CONTRACT_TYPES');
export const receiveContractTypes = action(
  RECEIVE_CONTRACT_TYPES,
  'contractTypes'
);
export const FETCH_CONTRACT_TYPES_ERROR = type('FETCH_CONTRACT_TYPES_ERROR');
export const fetchContractTypesError = action(
  FETCH_CONTRACT_TYPES_ERROR,
  'error'
);

// By estate
export const FETCH_CONTRACTS_BY_ESTATE = type('FETCH_CONTRACTS_BY_ESTATE');
export const fetchContractsByEstate = action(FETCH_CONTRACTS_BY_ESTATE, 'id');
export const RECEIVE_CONTRACTS_BY_ESTATE = type('RECEIVE_CONTRACTS_BY_ESTATE');
export const receiveContractsByEstate = action(
  RECEIVE_CONTRACTS_BY_ESTATE,
  'id',
  'contracts'
);

export const FETCH_CONTRACTS_BY_ESTATE_ERROR = type(
  'FETCH_CONTRACTS_BY_ESTATE_ERROR'
);
export const fetchContractsByEstateError = action(
  FETCH_CONTRACTS_BY_ESTATE_ERROR,
  'id',
  'error'
);
