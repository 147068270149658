import { createActionCreator as action, createType } from '../../utils/redux';
import namespace from './namespace';

const type = createType(namespace);
export const FETCH_PRODUCTS = type('FETCH_PRODUCTS');
export const fetchProducts = action(FETCH_PRODUCTS, 'page');

export const FETCH_FAILED = type('FETCH_FAILED');

// Create
export const SHOW_PRODUCT_CREATE_MODAL = type('SHOW_PRODUCT_CREATE_MODAL');
export const showProductCreateModal = action(SHOW_PRODUCT_CREATE_MODAL);
export const CLOSE_PRODUCT_CREATE_MODAL = type('CLOSE_PRODUCT_CREATE_MODAL');
export const closeProductCreateModal = action(CLOSE_PRODUCT_CREATE_MODAL);

export const CREATE_PRODUCT = type('CREATE_PRODUCT');
export const createProduct = action(CREATE_PRODUCT, 'product');

export const CREATE_PRODUCT_SUCCESS = type('CREATE_PRODUCT_SUCCESS');
export const createProductSuccess = action(CREATE_PRODUCT_SUCCESS, 'product');

export const CREATE_PRODUCT_ERROR = type('CREATE_PRODUCT_ERROR');
export const createProductError = action(CREATE_PRODUCT_ERROR, 'error');

// Edit
export const EDIT_PRODUCT = type('EDIT_PRODUCT');
export const editProduct = action(EDIT_PRODUCT, 'product');

// Delete
export const DELETE_PRODUCT = type('DELETE_PRODUCT');
export const deleteProduct = action(DELETE_PRODUCT, 'product');

export const RECEIVE_PRODUCTS = type('RECEIVE_PRODUCTS');
export const receiveProducts = action(RECEIVE_PRODUCTS, 'products');
export const RECEIVE_PAGE = type('RECEIVE_PAGE');
export const receivePage = action(RECEIVE_PAGE, 'data', 'pagination');

export const PRODUCT_DELETED = type('PRODUCT_DELETED');
export const productDeleted = action(PRODUCT_DELETED, 'product');

export const PRODUCT_CREATED = type('PRODUCT_CREATED');
export const productCreated = action(PRODUCT_CREATED, 'product');

export const PRODUCT_MODIFIED = type('PRODUCT_MODIFIED');
export const productModified = action(PRODUCT_MODIFIED, 'product');

// product types
export const FETCH_PRODUCT_TYPES = type('FETCH_PRODUCT_TYPES');
export const fetchProductTypes = action(FETCH_PRODUCT_TYPES);

export const RECEIVE_PRODUCT_TYPES = type('RECEIVE_PRODUCT_TYPES');
export const receiveProductTypes = action(
  RECEIVE_PRODUCT_TYPES,
  'productTypes'
);

export const FETCH_PRODUCTS_OF_TYPE = type('FETCH_PRODUCTS_OF_TYPE');
export const fetchProductsOfType = action(
  FETCH_PRODUCTS_OF_TYPE,
  'productType'
);

export const RECEIVE_PRODUCTS_OF_TYPE = type('RECEIVE_PRODUCTS_OF_TYPE');
export const receiveProductsOfType = action(
  RECEIVE_PRODUCTS_OF_TYPE,
  'productType',
  'products'
);
