import { createReducer } from '../../utils/redux';
import {
  FETCH_USER,
  RECEIVE_USER,
  SET_USER_TOKEN,
  LOGIN,
  LOGIN_FAILED,
  LOGOUT,
  CLEAR_USER,
  SET_ACTIVE_ORGANIZATION
} from './actions';
import initialState from './state';

export default createReducer(initialState, {
  [SET_USER_TOKEN]: (state, { token }) => {
    return { ...state, token };
  },
  [FETCH_USER]: state => ({ ...state, fetching: true }),
  [RECEIVE_USER]: (state, { user }) => {
    return { ...state, user, fetching: false, loaded: true };
  },
  [SET_ACTIVE_ORGANIZATION]: (state, { organization }) => ({
    ...state,
    activeOrganization: organization
  }),
  [LOGIN_FAILED]: state => ({ ...state, fetching: false }),
  [LOGIN]: state => ({ ...state, fetching: true }),
  [CLEAR_USER]: state => ({
    ...initialState,
    loaded: true,
    fetching: false
  }),
  // Do not clear out the 'loaded' prop as the application tries to relogin immediately after it
  [LOGOUT]: state => ({
    ...state,
    user: initialState.user,
    token: initialState.activeOrganization,
    activeOrganization: initialState.activeOrganization
  })
});
