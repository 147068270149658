export default {
  byState: {
    offered: {
      data: [],
      loaded: false,
      fetching: false,
      error: null,
      query: {
        sort: '-created_at',
        state: 'offered',
        include:
          'client,user,estate,estate.equipmentSurveyTranscript,contractType'
      },
      pagination: {
        count: 1,
        currentPage: 1,
        total: 1,
        totalPages: 1
      }
    },
    /* This is a 'custom' state (the contract model does not actually have this kind of state).
     * It is combination of 'accepted' and 'started' */
    dealed: {
      data: [],
      loaded: false,
      fetching: false,
      error: null,
      query: {
        sort: '-created_at',
        include:
          'client,user,estate,estate.contractingBlueprint,estate.measurement,estate.layout,estate.schematic,estate.technical,contractType'
      },
      /* This cannot be put inside the 'query' because the laravel-filterable library won't handle these encoded params correctly */
      qs: 'state=in=accepted|started',
      pagination: {
        count: 1,
        currentPage: 1,
        total: 1,
        totalPages: 1
      }
    },
    rejected: {
      data: [],
      loaded: false,
      fetching: false,
      error: null,
      query: {
        sort: '-created_at',
        state: 'rejected'
      },
      pagination: {
        count: 1,
        currentPage: 1,
        total: 1,
        totalPages: 1
      }
    }
  },
  contractsByEstate: {
    data: [],
    loaded: false,
    fetching: false,
    error: null,
    estateId: null
  },
  contractTypes: {
    data: [],
    loaded: false,
    fetching: false,
    error: null
  }
};
