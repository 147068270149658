import { combineReducers } from 'redux';
import userReducer from './user/reducer';
/* PLOP_APPEND_REDUCER_IMPORT */
import LeadsSlice from './leads';

import RolesReducer from './roles/reducer';
import PaymentTermsReducer from './payment-terms/reducer';
import PaymentTermsSlice from './payment-terms';
import ContractServicesReducer from './contract-services/reducer';
import ContractsReducer from './contracts/reducer';
import UsersReducer from './users/reducer';
import EstatesReducer from './estates/reducer';
import ClientsReducer from './clients/reducer';
import ClientTypesSlice from './client-types';
import ProductsReducer from './products/reducer';
import ProductsSlice from './products';
import TasksSlice from './tasks';
import task from './task';
import TaskTypesSlice from './task-types';
import UsersSlice from './users';
import ContractsSlice from './contracts';
import ContractTypesSlice from './contract-types';
import ContractSlice from './contract';
import InvoiceSlice from './invoice';
import InvoicesSlice from './invoices';
import ReportsSummary from './reports-summary';
import ReportsUsers from './reports-users';
import ContractServicesSlice from './contract-services';
import Notifications from './notifications';

import UiReducer from './ui/reducer';

export default combineReducers({
  user: userReducer,
  /* PLOP_APPEND_REDUCER_COMBINE */
  leads: LeadsSlice.reducer,

  roles: RolesReducer,

  paymentTerms: PaymentTermsReducer,
  paymentTermsTmp: PaymentTermsSlice.reducer,

  contractServices: ContractServicesReducer,
  contractServicesTmp: ContractServicesSlice.reducer,

  contracts: ContractsReducer,
  contractsTmp: ContractsSlice.reducer,
  contract: ContractSlice.reducer,
  contractTypes: ContractTypesSlice.reducer,

  tasks: TasksSlice.reducer,
  task,
  taskTypes: TaskTypesSlice.reducer,

  invoice: InvoiceSlice.reducer,
  invoices: InvoicesSlice.reducer,

  reportsSummary: ReportsSummary.reducer,
  reportsUsers: ReportsUsers.reducer,

  users: UsersReducer,
  usersTmp: UsersSlice.reducer,

  estates: EstatesReducer,
  clients: ClientsReducer,
  clientTypes: ClientTypesSlice.reducer,
  products: ProductsReducer,
  productsTmp: ProductsSlice.reducer,

  ui: UiReducer,

  notifications: Notifications
});
