import colors from './colors';
import layout from './layout';
import effects from './effects';

const buttons = {
  primary: {
    background: colors.green,
    color: colors.white,
    borderColor: colors.green
  },
  secondary: {
    background: '#f5f5f5',
    color: colors.black,
    borderColor: '#eeeeee'
  },
  link: {
    background: 'transparent',
    color: colors.black,
    borderColor: 'transparent'
  }
};

const getButtonBaseStyle = type => `
  background: ${buttons[type].background};
  color: ${buttons[type].color};
  border-color: ${buttons[type].borderColor};
`;

const buttonTheme = type => {
  switch (type) {
    case 'secondary':
    case 'primary':
      return `${getButtonBaseStyle(type)};`;
    case 'link':
      return `
      ${getButtonBaseStyle(type)};
      &:hover {
        text-decoration: underline;
        cursor: pointer
      }
      `;
    default:
      return `${getButtonBaseStyle('primary')}`;
  }
};

const buttonMixin = ({ block, disabled, buttonType, rounded }) => `
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  outline: none;
  padding: 6px 12px; // Border brings 1px
  border: 1px solid;
  border-radius: ${rounded ? '100px' : '2px'};

  // Inject theme for different buttons
  ${buttonTheme(buttonType)};

  // Block style button (takes 100% width)
  ${block ? 'display: block' : ''};
  ${block ? 'width: 100%' : ''};

  // Disabled button
  ${disabled ? 'opacity: 0.7' : ''};
`;

const alerts = {
  success: {
    background: '#eafaf0',
    color: colors.green,
    borderColor: colors.green
  },
  danger: {
    background: '#ffdddf',
    color: colors.red,
    borderColor: colors.red
  },
  warning: {
    background: '#fffbe7',
    color: colors.yellow,
    borderColor: colors.yellow
  }
};

const pills = {
  default: {
    background: colors.grayDark,
    color: colors.black
  },
  badgeLight: {
    background: 'transparent',
    color: colors.grayDarkest,
    border: `solid 1px  ${colors.grayDark}`,
    borderRadius: '4px'
  }
};

const pip = {
  all: {
    background: colors.black
  },
  default: {
    background: colors.grayDarker
  },
  lead: {
    background: colors.red
  },
  offer: {
    background: colors.orange
  },
  offered: {
    background: colors.orange
  },
  deal: {
    background: colors.yellow
  },
  accepted: {
    background: colors.yellow
  },
  rejected: {
    background: colors.grayDarker
  },
  onGoing: {
    background: colors.blueViolet
  },
  ready: {
    background: colors.grayDarker
  },
  started: {
    background: colors.blueViolet
  },
  ended: {
    background: colors.grayDarker
  },
  unbilled: {
    background: colors.green
  },
  billed: {
    background: colors.grayDarker
  },
  installation: {
    background: colors.yellow
  },
  inspection: {
    background: colors.babyBlue
  },
  yearlyInspection: {
    background: colors.blueViolet
  }
};

export default {
  colors,
  layout,
  effects,
  global,
  // Component specific attributes
  buttons,
  buttonMixin,
  pills,
  pip,
  alerts
};
