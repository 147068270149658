export default {
  users: {
    data: [],
    loaded: false,
    fetching: false,
    pagination: {
      count: 1,
      currentPage: 1,
      total: 1,
      totalPages: 1
    }
  },
  ui: {
    userCreateModal: {
      show: false,
      fetching: false,
      error: false
    }
  }
};
