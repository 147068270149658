export default {
  products: [],
  fetching: false,
  loaded: false,
  pagination: {
    count: 1,
    currentPage: 1,
    total: 1,
    totalPages: 1
  },
  productTypes: {
    loaded: false,
    fetching: true,
    data: []
  },
  productsByType: {},
  ui: {
    productCreateModal: {
      show: false,
      fetching: false,
      error: false
    }
  }
};
