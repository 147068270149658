import React from 'react';
import RouteContainer from 'containers/route-container';
import Notifications from 'components/Notifications';

export default function AppRoot(props) {
  return (
    <>
      <Notifications />
      <RouteContainer />
    </>
  );
}
