import { createSlice } from 'redux-starter-kit';
import { commonEntity } from 'utils/store';

export default createSlice({
  slice: 'invoice',
  initialState: {
    ...commonEntity.initialState
  },
  reducers: {
    ...commonEntity.reducers
  }
});
