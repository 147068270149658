import { take, takeEvery, fork, call, put, select } from 'redux-saga/effects';
import {
  FETCH_CLIENTS,
  CREATE_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  FETCH_FAILED,
  RECEIVE_CLIENTS,
  FETCH_CLIENT_TYPES,
  RECEIVE_CLIENT_TYPES,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_ERROR,
  CLOSE_CLIENT_CREATE_MODAL
} from './actions';
import { page } from './selectors';
import apiClient from '../../services/api-service';
import { normalize } from '../../utils/response';
import { actions as NotificationActions } from 'modules/notifications';
import { navigate } from 'modules/navigation/actions';

function* handleFetchClients({ page }) {
  try {
    const {
      data: {
        data: clients,
        meta: { pagination }
      }
    } = yield call(apiClient.get, '/V1/clients', {
      params: {
        include: 'estates',
        page
      }
    });
    yield put({
      type: RECEIVE_CLIENTS,
      clients: normalize(clients),
      pagination
    });
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

function* handleWatchFetchClients() {
  yield takeEvery(FETCH_CLIENTS, handleFetchClients);
}
function* handleCreateClient({ client }) {
  try {
    const res = yield call(apiClient.post, '/V1/clients', client);
    yield put({ type: CREATE_CLIENT_SUCCESS });
    yield put({ type: CLOSE_CLIENT_CREATE_MODAL });
    yield put(
      NotificationActions.add({
        variant: 'success',
        content: 'Asiakas luotu. Avaa klikkaamalla.',
        onClickAction: navigate(`/asiakkaat/${res.data.data.id}`)
      })
    );
  } catch {
    yield put({ type: CREATE_CLIENT_ERROR });
    yield put(
      NotificationActions.add({
        variant: 'danger',
        content: 'Asiakkaan luominen epäonnistui'
      })
    );
  }
}

function* handleWatchCreateClient() {
  yield takeEvery(CREATE_CLIENT, handleCreateClient);
}

function* handleEditClient({ client }) {
  try {
    yield call(apiClient.patch, `/V1/clients/${client.id}`, client);
    const p = yield select(page);
    yield put({ type: FETCH_CLIENTS, page: p });
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

function* handleWatchEditClient() {
  yield takeEvery(EDIT_CLIENT, handleEditClient);
}

function* handleDeleteClient({ client: { id } }) {
  try {
    yield call(apiClient.delete, `/V1/clients/${id}`);
    const p = yield select(page);
    yield put({ type: FETCH_CLIENTS, page: p });
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

function* handleWatchDeleteClient() {
  yield takeEvery(DELETE_CLIENT, handleDeleteClient);
}

function* handleFetchClientTypes() {
  yield take(FETCH_CLIENT_TYPES);
  try {
    const {
      data: { data: clientTypes }
    } = yield call(apiClient.get, '/V1/client-types');
    yield put({ type: RECEIVE_CLIENT_TYPES, clientTypes });
  } catch {
    yield put({ type: FETCH_FAILED });
  }
}

// Main watcher
export default function* watch() {
  yield fork(handleWatchFetchClients);
  yield fork(handleWatchCreateClient);
  yield fork(handleWatchEditClient);
  yield fork(handleWatchDeleteClient);
  yield fork(handleFetchClientTypes);
}
