import { createReducer } from '../../utils/redux';
import { defaultTo } from 'ramda';
import {
  FETCH_USERS,
  RECEIVE_USERS,
  SHOW_USER_CREATE_MODAL,
  CLOSE_USER_CREATE_MODAL,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  FETCH_USERS_ERROR,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR
} from './actions';
import initialState from './state';
import { pageRootless as selectPage } from './selectors';

export default createReducer(initialState, {
  [FETCH_USERS]: (state, { page }) => {
    const currentPage = defaultTo(selectPage(state), page);
    return {
      ...state,
      users: {
        ...state.users,
        fetching: true,
        pagination: {
          ...state.users.pagination,
          currentPage
        }
      }
    };
  },
  [RECEIVE_USERS]: (state, { users, pagination }) => {
    return {
      ...state,
      users: { data: users, pagination, fetching: false, loaded: true }
    };
  },
  [SHOW_USER_CREATE_MODAL]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        userCreateModal: {
          ...state.ui.userCreateModal,
          show: true
        }
      }
    };
  },
  [CLOSE_USER_CREATE_MODAL]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        userCreateModal: {
          ...state.ui.userCreateModal,
          show: false
        }
      }
    };
  },
  [CREATE_USER]: (state, { user }) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        userCreateModal: {
          ...state.ui.userCreateModal,
          fetching: true,
          error: false
        }
      }
    };
  },
  [CREATE_USER_SUCCESS]: state => {
    return {
      ...state,
      ui: {
        ...state.ui,
        userCreateModal: {
          ...state.ui.userCreateModal,
          fetching: false,
          error: false
        }
      }
    };
  },
  [CREATE_USER_ERROR]: (state, { error }) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        userCreateModal: {
          ...state.ui.userCreateModal,
          fetching: false,
          error: true
        }
      }
    };
  },
  [EDIT_USER]: (state, { user }) => {
    return { ...state, user };
  },
  [DELETE_USER]: (state, { user }) => {
    return { ...state, user };
  },
  [FETCH_USERS_ERROR]: (state, { error }) => {
    return { ...state, error };
  },
  [EDIT_USER_SUCCESS]: state => {
    return { ...state };
  },
  [EDIT_USER_ERROR]: (state, { error }) => {
    return { ...state, error };
  }
});
