import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

const disableClicking = e => {
  e.stopPropagation();
  e.preventDefault();
};

const Loader = ({
  className = '',
  loading = true,
  text = 'Ladataan',
  fullscreen = false,
  children
}) => {
  return (
    <div
      onClick={loading ? disableClicking : null}
      className={`loader ${!!loading ? 'loading' : ''} ${
        !!fullscreen ? 'loader-fullscreen' : ''
      }${className}`}
    >
      {loading && (
        <div className="overlay">
          <Spinner variant="primary" animation="grow" />
          <div className="text">{text}</div>
        </div>
      )}
      {fullscreen ? (
        <div className="backdrop" />
      ) : (
        <div className="backdrop">{children}</div>
      )}
    </div>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool,
  fullscreen: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node,
  top: PropTypes.string
};
Loader.defaultProps = {
  loading: true,
  text: 'Ladataan',
  fullscreen: false
};

export const ContentLoader = Loader;
export const FullscreenLoader = props => <Loader fullscreen {...props} />;

export default Loader;
